import React, { Fragment } from 'react';
import { createContainer } from 'unstated-next';
import { useLocation, useHistory } from 'react-router-dom';
import qs from 'query-string';
import CircleLoader from 'components/CircleLoader/CircleLoader';
import { P1 } from 'components/Typography/Typography';
import Auth from 'unstated/Auth';

const LicenseBuilderModal = React.lazy(
  () => import('components/LicenseBuilder/LicenseBuilderModal'),
);
const LicenseEditorModal = React.lazy(
  () => import('components/LicenseEditor/LicenseEditorModal'),
);
const RemoveClipModal = React.lazy(
  () => import('components/RemoveClipModal/RemoveClipModal'),
);
const StartNewProjectModal = React.lazy(
  () => import('components/StartNewProjectModal/StartNewProjectModal'),
);
const AddToProjectModal = React.lazy(
  () => import('components/AddToProjectPopover/AddToProjectModal'),
);
const DeleteProjectModal = React.lazy(
  () => import('components/DeleteProjectModal/DeleteProjectModal'),
);
const RemoveClipFromProjectModal = React.lazy(
  () =>
    import('components/RemoveClipFromProjectModal/RemoveClipFromProjectModal'),
);
const ClearCartModal = React.lazy(
  () => import('components/ClearCartModal/ClearCartModal'),
);
const LoginModal = React.lazy(() => import('components/LoginModal/LoginModal'));
const CopyProjectModal = React.lazy(
  () => import('components/CopyProjectModal/CopyProjectModal'),
);
const ProjectClipModal = React.lazy(
  () => import('components/ProjectClipModal/ProjectClipModal'),
);
const CompleteProfileModal = React.lazy(
  () => import('components/CompleteProfileModal/CompleteProfileModal'),
);
const FootageResearchModal = React.lazy(
  () => import('components/FootageResearchModal/FootageResearchModal'),
);
const QuoteModal = React.lazy(() => import('components/QuoteModal/QuoteModal'));
const CommercialServicesModal = React.lazy(
  () => import('components/CommercialServicesModal/CommercialServicesModal'),
);
const AddPaymentMethodModalBraintree = React.lazy(
  () =>
    import('components/AddPaymentMethodModal/AddPaymentMethodModalBraintree'),
);
const DeletePaymentMethodModalBraintree = React.lazy(
  () =>
    import(
      'components/DeletePaymentMethodModal/DeletePaymentMethodModalBraintree'
    ),
);
const DownloadClipModal = React.lazy(
  () => import('components/DownloadClipModal/DownloadClipModal'),
);
const TermsModal = React.lazy(() => import('components/TermsModal/TermsModal'));
const InvoiceModal = React.lazy(
  () => import('components/InvoiceModal/InvoiceModal'),
);
const VideoPreviewModal = React.lazy(
  () => import('components/VideoPreviewModal/VideoPreviewModal'),
);
const FormatInfoModal = React.lazy(
  () => import('components/FormatInfoModal/FormatInfoModal'),
);
const EditorialHelpModal = React.lazy(
  () => import('components/EditorialHelpModal/EditorialHelpModal'),
);
const BraintreeErrorModal = React.lazy(
  () => import('components/Braintree/BraintreeErrorModal'),
);
const FilmmakerBioModal = React.lazy(
  () => import('components/FilmmakerBioModal/FilmmakerBioModal'),
);
const ThemeModal = React.lazy(() => import('components/ThemeModal/ThemeModal'));
const FootageResearchTrigger = React.lazy(
  () => import('components/FootageResearchTrigger/FootageResearchTrigger'),
);
const UnwatermarkedDownloadModal = React.lazy(
  () =>
    import('components/UnwatermarkedDownloadModal/UnwatermarkedDownloadModal'),
);
const DisconnectAccountModal = React.lazy(
  () => import('components/Auth/Providers/Google/DisconnectAccountModal'),
);
const RequestUnwateredMarkedDownloadsModal = React.lazy(
  () =>
    import(
      'components/RequestUnwatermarkedDownloadsModal/RequestUnwatermarkedDownloadsModal'
    ),
);

const useModal = () => {
  const auth = Auth.useContainer();

  let [licenseBuilderModalState, setLicenseBuilderModalState] = React.useState({
    isOpen: false,
    data: null,
  });
  let [licenseEditorModalState, setLicenseEditorModalState] = React.useState({
    isOpen: false,
    data: null,
  });
  let [removeClipModalState, setRemoveClipModalState] = React.useState({
    isOpen: false,
    data: null,
  });
  let [addToProjectState, setAddToProjectState] = React.useState({
    isOpen: false,
    data: null,
  });
  let [deleteProjectState, setDeleteProjectState] = React.useState({
    isOpen: false,
    data: null,
  });
  let [removeClipFromProjectState, setRemoveClipFromProjectState] =
    React.useState({ isOpen: false, data: null });
  let [clearCartModalState, setClearCartModalState] = React.useState({
    isOpen: false,
  });
  let [loginModalState, setLoginModalState] = React.useState({
    isOpen: false,
    onComplete: null,
    onCancel: null,
    text: '',
    initialView: 'login',
  });
  let [startNewProjectModalState, setStartNewProjectModalState] =
    React.useState({ isOpen: false });
  let [copyProjectModalState, setCopyProjectModalState] = React.useState({
    isOpen: false,
    data: null,
  });
  let [projectClipModalState, setProjectClipModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });
  let [completeProfileModalState, setCompleteProfileModalState] =
    React.useState({ isOpen: false, onComplete: null });
  let [footageResearchModalState, setFootageResearchModalState] =
    React.useState({ isOpen: false, onComplete: null });

  let [quoteModalState, setQuoteModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });
  let [commercialServicesModalState, setCommercialServicesModalState] =
    React.useState({
      isOpen: false,
      data: null,
      onComplete: null,
    });

  let [addPaymentMethodModalState, setAddPaymentMethodModalState] =
    React.useState({
      isOpen: false,
      onComplete: null,
      onCancel: null,
    });

  let [deletePaymentMethodModalState, setDeletePaymentMethodModalState] =
    React.useState({
      isOpen: false,
      onComplete: null,
      onCancel: null,
      isPaypal: false,
      paymentMethodId: null,
    });

  let [downloadClipModalState, setDownloadClipModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });

  let [termsModalState, setTermsModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });

  let [invoiceModalState, setInvoiceModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });
  let [videoPreviewModalState, setVideoPreviewModalState] = React.useState({
    isOpen: false,
    data: null,
  });

  let [themeModalState, setThemeModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });
  let [formatInfoModalState, setFormatInfoModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });
  let [editorialHelpModalState, setEditorialHelpModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });
  let [braintreeErrorModalState, setBraintreeErrorModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });
  let [filmmakerBioModalState, setFilmmakerBioModalState] = React.useState({
    isOpen: false,
    data: null,
    onComplete: null,
  });
  let [footageResearchTriggerState, setFootageResearchTriggerState] =
    React.useState({
      isOpen: false,
      onComplete: null,
    });
  let [unwatermarkedDownloadModalState, setUnwatermarkedDownloadModalState] =
    React.useState({
      isOpen: false,
      clip: null,
    });

  const [
    requestUnwatermarkedDownloadsModalState,
    setRequestUnwatermarkedDownloadsModalState,
  ] = React.useState({
    isOpen: false,
    data: null,
  });

  const [disconnectAccountModalState, setDisconnectAccountModalState] =
    React.useState({
      isOpen: false,
      data: null,
    });

  let licenseBuilderModal = {
    isOpen: licenseBuilderModalState.isOpen,
    data: licenseBuilderModalState.data,
    open: ({ data }) => setLicenseBuilderModalState({ isOpen: true, data }),
    close: () => setLicenseBuilderModalState({ isOpen: false, data: null }),
  };

  let licenseEditorModal = {
    isOpen: licenseEditorModalState.isOpen,
    data: licenseEditorModalState.data,
    open: ({ data }) => setLicenseEditorModalState({ isOpen: true, data }),
    close: () => setLicenseEditorModalState({ isOpen: false, data: null }),
  };

  let removeClipModal = {
    isOpen: removeClipModalState.isOpen,
    data: removeClipModalState.data,
    open: ({ data }) => setRemoveClipModalState({ isOpen: true, data }),
    close: React.useCallback(
      () => setRemoveClipModalState({ isOpen: false, data: null }),
      [],
    ),
  };

  let addToProjectModal = {
    isOpen: addToProjectState.isOpen,
    data: addToProjectState.data,
    open: ({ data }) => setAddToProjectState({ isOpen: true, data }),
    close: React.useCallback(
      () => setAddToProjectState({ isOpen: false, data: null }),
      [],
    ),
  };

  let deleteProjectModal = {
    isOpen: deleteProjectState.isOpen,
    data: deleteProjectState.data,
    open: ({ data }) => setDeleteProjectState({ isOpen: true, data }),
    close: React.useCallback(
      () => setDeleteProjectState({ isOpen: false, data: null }),
      [],
    ),
  };

  let removeClipFromProjectModal = {
    isOpen: removeClipFromProjectState.isOpen,
    data: removeClipFromProjectState.data,
    open: ({ data }) => setRemoveClipFromProjectState({ isOpen: true, data }),
    close: React.useCallback(
      (id) => setRemoveClipFromProjectState({ isOpen: false, data: id }),
      [],
    ),
  };

  let clearCartModal = {
    isOpen: clearCartModalState.isOpen,
    data: clearCartModalState.data,
    open: () => setClearCartModalState({ isOpen: true }),
    close: React.useCallback(
      () => setClearCartModalState({ isOpen: false }),
      [],
    ),
  };

  let loginModal = {
    isOpen: loginModalState.isOpen,
    text: loginModalState.text,
    initialView: loginModalState.initialView,
    onCancel: loginModalState.onCancel,
    open: ({ onComplete, text, initialView, onCancel } = {}) =>
      setLoginModalState({
        isOpen: true,
        onComplete: onComplete ? onComplete : loginModalState.onComplete,
        text: text ? text : loginModalState.text,
        initialView: initialView ? initialView : loginModalState.initialView,
        onCancel: onCancel ? onCancel : loginModalState.onCancel,
      }),
    close: React.useCallback(() => {
      setLoginModalState({
        isOpen: false,
        onComplete: null,
        text: '',
        initialView: 'register',
        onCancel: null,
      });
    }, []),
    onComplete: loginModalState.onComplete
      ? async () => {
          await loginModalState.onComplete();
          setLoginModalState({
            isOpen: false,
            onComplete: null,
            text: '',
            initialView: 'register',
            onCancel: null,
          });
        }
      : null,
  };

  let startNewProjectModal = {
    isOpen: startNewProjectModalState.isOpen,
    data: startNewProjectModalState.data,
    open: React.useCallback(
      () => setStartNewProjectModalState({ isOpen: true }),
      [],
    ),
    close: React.useCallback(
      () => setStartNewProjectModalState({ isOpen: false }),
      [],
    ),
  };

  let copyProjectModal = {
    isOpen: copyProjectModalState.isOpen,
    data: copyProjectModalState.data,
    open: React.useCallback(
      ({ data }) => setCopyProjectModalState({ isOpen: true, data }),
      [],
    ),
    close: React.useCallback(
      () => setCopyProjectModalState({ isOpen: false, data: null }),
      [],
    ),
  };

  let projectClipModal = {
    isOpen: projectClipModalState.isOpen,
    data: projectClipModalState.data,
    open: ({ onComplete, data }) =>
      setProjectClipModalState({ isOpen: true, onComplete, data }),
    close: React.useCallback(
      () =>
        setProjectClipModalState({
          isOpen: false,
          onComplete: null,
          data: null,
        }),
      [],
    ),
    onComplete: projectClipModalState.onComplete
      ? async () => {
          await projectClipModalState.onComplete();
          setProjectClipModalState({
            isOpen: false,
            onComplete: null,
            data: null,
          });
        }
      : null,
  };

  let completeProfileModal = {
    isOpen: completeProfileModalState.isOpen,
    open: async () => {
      void setCompleteProfileModalState({ isOpen: true });
    },
    close: React.useCallback(
      () => setCompleteProfileModalState({ isOpen: false }),
      [],
    ),
  };

  let footageResearchModal = {
    isOpen: footageResearchModalState.isOpen,
    open: () => setFootageResearchModalState({ isOpen: true }),
    close: React.useCallback(
      () => setFootageResearchModalState({ isOpen: false }),
      [],
    ),
  };

  let quoteModal = {
    isOpen: quoteModalState.isOpen,
    open: React.useCallback(() => setQuoteModalState({ isOpen: true }), []),
    close: React.useCallback(() => setQuoteModalState({ isOpen: false }), []),
  };

  let commercialServicesModal = {
    isOpen: commercialServicesModalState.isOpen,
    open: React.useCallback(
      () => setCommercialServicesModalState({ isOpen: true }),
      [],
    ),
    close: React.useCallback(
      () => setCommercialServicesModalState({ isOpen: false }),
      [],
    ),
  };

  let addPaymentMethodModal = {
    isOpen: addPaymentMethodModalState.isOpen,
    open: ({ onComplete }) =>
      setAddPaymentMethodModalState({
        isOpen: true,
        ...(onComplete && { onComplete }),
      }),
    close: React.useCallback(
      () => setAddPaymentMethodModalState({ isOpen: false, onComplete: null }),
      [],
    ),
    onComplete: addPaymentMethodModalState.onComplete
      ? () => {
          addPaymentMethodModalState.onComplete();
          setAddPaymentMethodModalState({ isOpen: false, onComplete: null });
        }
      : null,
  };

  let deletePaymentMethodModal = {
    isOpen: deletePaymentMethodModalState.isOpen,
    isPaypal: deletePaymentMethodModalState.isPaypal,
    paymentMethodId: deletePaymentMethodModalState.paymentMethodId,
    open: ({ onComplete, isPaypal, paymentMethodId }) =>
      setDeletePaymentMethodModalState({
        isOpen: true,
        ...(onComplete && { onComplete }),
        ...(isPaypal && { isPaypal }),
        ...(paymentMethodId && { paymentMethodId }),
      }),
    close: React.useCallback(
      () =>
        setDeletePaymentMethodModalState({
          isOpen: false,
          onComplete: null,
          isPaypal: null,
          paymentMethodId: null,
        }),
      [],
    ),
    onComplete: deletePaymentMethodModalState.onComplete
      ? () => {
          deletePaymentMethodModalState.onComplete();
          setDeletePaymentMethodModalState({
            isOpen: false,
            onComplete: null,
            isPaypal: null,
            paymentMethodId: null,
          });
        }
      : null,
  };
  let downloadClipModal = {
    isOpen: downloadClipModalState.isOpen,
    data: downloadClipModalState.data,
    open: ({ onComplete, data }) =>
      setDownloadClipModalState({ isOpen: true, onComplete, data }),
    close: React.useCallback(
      () =>
        setDownloadClipModalState({
          isOpen: false,
          onComplete: null,
          data: null,
        }),
      [],
    ),
    onComplete: downloadClipModalState.onComplete
      ? async () => {
          await downloadClipModalState.onComplete();
          setDownloadClipModalState({
            isOpen: false,
            onComplete: null,
            data: null,
          });
        }
      : null,
  };

  let termsModal = {
    isOpen: termsModalState.isOpen,
    data: termsModalState.data,
    open: React.useCallback(
      ({ data }) => setTermsModalState({ isOpen: true, data }),
      [],
    ),
    close: React.useCallback(
      () => setTermsModalState({ isOpen: false, data: null }),
      [],
    ),
  };

  let invoiceModal = {
    isOpen: invoiceModalState.isOpen,
    data: invoiceModalState.data,
    open: React.useCallback(
      ({ data }) => setInvoiceModalState({ isOpen: true, data }),
      [],
    ),
    close: React.useCallback(
      () => setInvoiceModalState({ isOpen: false, data: null }),
      [],
    ),
  };

  let videoPreviewModal = {
    isOpen: videoPreviewModalState.isOpen,
    data: videoPreviewModalState.data,
    open: React.useCallback(
      ({ data }) => setVideoPreviewModalState({ isOpen: true, data }),
      [],
    ),
    close: React.useCallback(
      () => setVideoPreviewModalState({ isOpen: false, data: null }),
      [],
    ),
  };

  let themeModal = {
    isOpen: themeModalState.isOpen,
    data: themeModalState.data,
    open: React.useCallback(
      ({ data }) => setThemeModalState({ isOpen: true, data }),
      [],
    ),
    close: React.useCallback(
      () => setThemeModalState({ isOpen: false, data: null }),
      [],
    ),
    onComplete: themeModalState.onComplete
      ? async () => {
          await themeModalState.onComplete();
          setThemeModalState({ isOpen: false, onComplete: null, data: null });
        }
      : null,
  };

  let formatInfoModal = {
    isOpen: formatInfoModalState.isOpen,
    data: formatInfoModalState.data,
    open: React.useCallback(
      ({ data }) => setFormatInfoModalState({ isOpen: true, data }),
      [],
    ),
    close: React.useCallback(
      () => setFormatInfoModalState({ isOpen: false, data: null }),
      [],
    ),
    onComplete: null,
  };

  let editorialHelpModal = {
    isOpen: editorialHelpModalState.isOpen,
    data: editorialHelpModalState.data,
    open: React.useCallback(
      ({ data }) => setEditorialHelpModalState({ isOpen: true, data }),
      [],
    ),
    close: React.useCallback(
      () => setEditorialHelpModalState({ isOpen: false, data: null }),
      [],
    ),
    onComplete: null,
  };

  let braintreeErrorModal = {
    isOpen: braintreeErrorModalState.isOpen,
    data: braintreeErrorModalState.data,
    open: React.useCallback(
      ({ data }) => setBraintreeErrorModalState({ isOpen: true, data }),
      [],
    ),
    close: React.useCallback(
      () => setBraintreeErrorModalState({ isOpen: false, data: null }),
      [],
    ),
    onComplete: null,
  };

  let filmmakerBioModal = {
    isOpen: filmmakerBioModalState.isOpen,
    data: filmmakerBioModalState.data,
    open: ({ data }) => setFilmmakerBioModalState({ isOpen: true, data }),
    close: () => setFilmmakerBioModalState({ isOpen: false, data: null }),
  };

  let footageResearchTrigger = {
    isOpen: footageResearchTriggerState.isOpen,
    open: React.useCallback(
      () => setFootageResearchTriggerState({ isOpen: true }),
      [],
    ),
    close: () => setFootageResearchTriggerState({ isOpen: false }),
  };

  let unwatermarkedDownloadModal = {
    isOpen: unwatermarkedDownloadModalState.isOpen,
    clip: unwatermarkedDownloadModalState.clip,
    open: React.useCallback(
      ({ clip }) => setUnwatermarkedDownloadModalState({ isOpen: true, clip }),
      [],
    ),
    close: React.useCallback(
      () => setUnwatermarkedDownloadModalState({ isOpen: false }),
      [],
    ),
  };

  const requestUnwatermarkedDownloadsModal = {
    isOpen: requestUnwatermarkedDownloadsModalState.isOpen,
    data: requestUnwatermarkedDownloadsModalState.data,
    open: () => {
      setRequestUnwatermarkedDownloadsModalState({ isOpen: true });
    },
    close: () => {
      setRequestUnwatermarkedDownloadsModalState({ isOpen: false });
    },
  };

  const disconnectAccountModal = {
    isOpen: disconnectAccountModalState.isOpen,
    data: disconnectAccountModalState.data,
    open: ({ onComplete, data = null }) =>
      void setDisconnectAccountModalState({ isOpen: true, data, onComplete }),
    close: () =>
      void setDisconnectAccountModalState({
        isOpen: false,
        data: null,
        onComplete: null,
      }),
    onComplete: async () => {
      await disconnectAccountModalState.onComplete?.();
      void setDisconnectAccountModalState({
        isOpen: false,
        onComplete: null,
        data: null,
      });
    },
  };

  return {
    licenseBuilderModal,
    licenseEditorModal,
    removeClipModal,
    addToProjectModal,
    deleteProjectModal,
    removeClipFromProjectModal,
    clearCartModal,
    loginModal,
    startNewProjectModal,
    copyProjectModal,
    projectClipModal,
    completeProfileModal,
    footageResearchModal,
    quoteModal,
    commercialServicesModal,
    addPaymentMethodModal,
    deletePaymentMethodModal,
    downloadClipModal,
    invoiceModal,
    termsModal,
    videoPreviewModal,
    themeModal,
    formatInfoModal,
    filmmakerBioModal,
    footageResearchTrigger,
    unwatermarkedDownloadModal,
    editorialHelpModal,
    braintreeErrorModal,
    disconnectAccountModal,
    requestUnwatermarkedDownloadsModal,
  };
};

const Modals = createContainer(useModal);

const isSkipCompleteProfileRoute = (path) => {
  const excludedPaths = ['/footage-research-form'];
  return excludedPaths.includes(path);
};

const GlobalModals = () => {
  const {
    licenseBuilderModal,
    licenseEditorModal,
    removeClipModal,
    addToProjectModal,
    deleteProjectModal,
    removeClipFromProjectModal,
    clearCartModal,
    loginModal,
    startNewProjectModal,
    copyProjectModal,
    projectClipModal,
    completeProfileModal,
    footageResearchModal,
    quoteModal,
    commercialServicesModal,
    addPaymentMethodModal,
    deletePaymentMethodModal,
    downloadClipModal,
    termsModal,
    invoiceModal,
    videoPreviewModal,
    themeModal,
    formatInfoModal,
    filmmakerBioModal,
    footageResearchTrigger,
    unwatermarkedDownloadModal,
    editorialHelpModal,
    braintreeErrorModal,
    disconnectAccountModal,
    requestUnwatermarkedDownloadsModal,
  } = Modals.useContainer();
  const { user, requiresProfileCompletion } = Auth.useContainer();
  const userLoggedIn = user?.email;
  const location = useLocation();
  const history = useHistory();

  const handleOpenModal = React.useCallback(
    (name) => {
      switch (name) {
        case 'login': {
          if (!loginModal.isOpen) loginModal.open();
          return;
        }
        case 'register': {
          if (!loginModal.isOpen) loginModal.open();
          return;
        }
        case 'footageResearch': {
          if (!footageResearchModal.isOpen) footageResearchModal.open();
          return;
        }
        case 'newProject': {
          if (!startNewProjectModal.isOpen) startNewProjectModal.open();
          return;
        }
        default: {
          return;
        }
      }
    },
    [loginModal, startNewProjectModal, footageResearchModal],
  );
  // check location and trigger modal if exists
  React.useEffect(() => {
    const { modal, ...restParams } = qs.parse(location.search);
    const searchParams = qs.stringify(restParams);
    if (modal) {
      handleOpenModal(modal);
      history.push(
        `${location.pathname}${searchParams ? `?${searchParams}` : ''}`,
      );
    }
  }, [handleOpenModal, history, location]);

  React.useEffect(() => {
    const restrictedUrls = ['/cart', '/cart/success', '/login', '/register'];
    const routeNotRestricted = restrictedUrls.indexOf(location.pathname) === -1;

    const mustCompleteProfile =
      requiresProfileCompletion &&
      !isSkipCompleteProfileRoute(location.pathname);

    if (routeNotRestricted && mustCompleteProfile) {
      completeProfileModal.open();
    } else {
      completeProfileModal.close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, user]);

  return (
    <Fragment>
      <React.Suspense
        fallback={
          <CircleLoader>
            <P1 center color="primary" bold css={{ marginTop: 20 }}>
              Loading
            </P1>
          </CircleLoader>
        }
      >
        {licenseBuilderModal.isOpen && (
          <LicenseBuilderModal
            isOpen={licenseBuilderModal.isOpen}
            data={licenseBuilderModal.data}
            onClose={licenseBuilderModal.close}
          />
        )}

        {licenseEditorModal.isOpen && (
          <LicenseEditorModal
            isOpen={licenseEditorModal.isOpen}
            data={licenseEditorModal.data}
            onClose={licenseEditorModal.close}
          />
        )}

        {removeClipModal.isOpen && (
          <RemoveClipModal
            isOpen={removeClipModal.isOpen}
            data={removeClipModal.data}
            onClose={removeClipModal.close}
          />
        )}

        {addToProjectModal.isOpen && (
          <AddToProjectModal
            isOpen={addToProjectModal.isOpen}
            data={addToProjectModal.data}
            onClose={addToProjectModal.close}
          />
        )}

        {userLoggedIn && deleteProjectModal.isOpen && (
          <DeleteProjectModal
            isOpen={deleteProjectModal.isOpen}
            data={deleteProjectModal.data}
            onClose={deleteProjectModal.close}
          />
        )}

        {removeClipFromProjectModal.isOpen && (
          <RemoveClipFromProjectModal
            isOpen={removeClipFromProjectModal.isOpen}
            data={removeClipFromProjectModal.data}
            onClose={removeClipFromProjectModal.close}
          />
        )}

        {clearCartModal.isOpen && (
          <ClearCartModal
            isOpen={clearCartModal.isOpen}
            onClose={clearCartModal.close}
          />
        )}

        {loginModal.isOpen && (
          <LoginModal
            isOpen={loginModal.isOpen}
            onClose={loginModal.close}
            onComplete={loginModal.onComplete}
            onCancel={loginModal.onCancel}
            initialView={loginModal.initialView}
          />
        )}

        {startNewProjectModal.isOpen && (
          <StartNewProjectModal
            isOpen={startNewProjectModal.isOpen}
            data={startNewProjectModal.data}
            onClose={startNewProjectModal.close}
          />
        )}

        {copyProjectModal.isOpen && (
          <CopyProjectModal
            isOpen={copyProjectModal.isOpen}
            data={copyProjectModal.data}
            onClose={copyProjectModal.close}
          />
        )}

        {projectClipModal.isOpen && (
          <ProjectClipModal
            isOpen={projectClipModal.isOpen}
            data={projectClipModal.data}
            onClose={projectClipModal.close}
            onComplete={projectClipModal.onComplete}
          />
        )}

        {quoteModal.isOpen && (
          <QuoteModal isOpen={quoteModal.isOpen} onClose={quoteModal.close} />
        )}

        {commercialServicesModal.isOpen && (
          <CommercialServicesModal
            isOpen={commercialServicesModal.isOpen}
            onClose={commercialServicesModal.close}
          />
        )}

        {addPaymentMethodModal.isOpen && (
          <AddPaymentMethodModalBraintree
            isOpen={addPaymentMethodModal.isOpen}
            onClose={addPaymentMethodModal.close}
            onComplete={addPaymentMethodModal.onComplete}
          />
        )}

        {deletePaymentMethodModal.isOpen && (
          <DeletePaymentMethodModalBraintree
            isOpen={deletePaymentMethodModal.isOpen}
            onClose={deletePaymentMethodModal.close}
            onComplete={deletePaymentMethodModal.onComplete}
            paymentMethodId={deletePaymentMethodModal.paymentMethodId}
          />
        )}

        {downloadClipModal.isOpen && (
          <DownloadClipModal
            isOpen={downloadClipModal.isOpen}
            data={downloadClipModal.data}
            onClose={downloadClipModal.close}
            onComplete={downloadClipModal.onComplete}
          />
        )}

        {termsModal.isOpen && (
          <TermsModal
            isOpen={termsModal.isOpen}
            data={termsModal.data}
            onClose={termsModal.close}
            onComplete={termsModal.onComplete}
          />
        )}

        {invoiceModal.isOpen && (
          <InvoiceModal
            isOpen={invoiceModal.isOpen}
            data={invoiceModal.data}
            onClose={invoiceModal.close}
            onComplete={invoiceModal.onComplete}
          />
        )}

        {videoPreviewModal.isOpen && (
          <VideoPreviewModal
            isOpen={videoPreviewModal.isOpen}
            data={videoPreviewModal.data}
            onClose={videoPreviewModal.close}
          />
        )}

        {themeModal.isOpen && (
          <ThemeModal
            isOpen={themeModal.isOpen}
            data={themeModal.data}
            onClose={themeModal.close}
            onComplete={themeModal.onComplete}
          />
        )}

        {formatInfoModal.isOpen && (
          <FormatInfoModal
            isOpen={formatInfoModal.isOpen}
            data={formatInfoModal.data}
            onClose={formatInfoModal.close}
            onComplete={formatInfoModal.onComplete}
          />
        )}

        {editorialHelpModal.isOpen && (
          <EditorialHelpModal
            isOpen={editorialHelpModal.isOpen}
            data={editorialHelpModal.data}
            onClose={editorialHelpModal.close}
            onComplete={editorialHelpModal.onComplete}
          />
        )}

        {braintreeErrorModal.isOpen && (
          <BraintreeErrorModal
            isOpen={braintreeErrorModal.isOpen}
            data={braintreeErrorModal.data}
            onClose={braintreeErrorModal.close}
            onComplete={braintreeErrorModal.onComplete}
          />
        )}

        {filmmakerBioModal.isOpen && (
          <FilmmakerBioModal
            isOpen={filmmakerBioModal.isOpen}
            data={filmmakerBioModal.data}
            onClose={filmmakerBioModal.close}
            onComplete={filmmakerBioModal.onComplete}
          />
        )}

        {completeProfileModal.isOpen && (
          <CompleteProfileModal
            isOpen={completeProfileModal.isOpen}
            onClose={completeProfileModal.close}
          />
        )}

        {footageResearchModal.isOpen && (
          <FootageResearchModal
            isOpen={footageResearchModal.isOpen}
            onClose={footageResearchModal.close}
          />
        )}

        {footageResearchTrigger.isOpen && (
          <FootageResearchTrigger
            isOpen={footageResearchTrigger.isOpen}
            onClose={footageResearchTrigger.close}
          />
        )}

        {unwatermarkedDownloadModal.isOpen && (
          <UnwatermarkedDownloadModal
            isOpen={unwatermarkedDownloadModal.isOpen}
            clip={unwatermarkedDownloadModal.clip}
            onClose={unwatermarkedDownloadModal.close}
          />
        )}

        {disconnectAccountModal.isOpen && (
          <DisconnectAccountModal
            isOpen={disconnectAccountModal.isOpen}
            onClose={disconnectAccountModal.close}
            onComplete={disconnectAccountModal.onComplete}
          />
        )}

        {requestUnwatermarkedDownloadsModal.isOpen && (
          <RequestUnwateredMarkedDownloadsModal
            isOpen={requestUnwatermarkedDownloadsModal.isOpen}
            onClose={requestUnwatermarkedDownloadsModal.close}
          />
        )}
      </React.Suspense>
    </Fragment>
  );
};

export default Modals;

export { GlobalModals };
