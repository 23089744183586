import React from 'react';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { withFormState } from 'informed';
import {
  InputWrapper,
  Label,
  Error,
  TextInput
} from './styles/StyledFormComponents';

const InformedInput = withFormState(
  ({
    type,
    field,
    width,
    maxWidth,
    className,
    formState,
    required,
    errorMessage,
    defaultValue,
    validationSchema,
    ...restProps
  }) => {
    const schema =
      validationSchema || getValidationType(type, required, restProps.label);

    const { label, ...labelProps } = restProps;

    return (
      <InputWrapper width={width} maxWidth={maxWidth} className={className}>
        {type && (
          <TextInput
            type={type}
            {...restProps}
            field={field}
            isDirty={formState.values[field]}
            validateOnBlur
            validateOnChange={type === 'password'}
            validationSchema={schema}
            errorMessage={errorMessage || formState.errors[field]}
            initialValue={defaultValue}
          />
        )}
        {restProps.label && (
          <Label {...labelProps}>
            <p>{restProps.label}</p>
          </Label>
        )}
        {(errorMessage || formState.errors[field]) && (
          <Error>{errorMessage || formState.errors[field]}</Error>
        )}
      </InputWrapper>
    );
  }
);

//Simple validation to check against input types
const getValidationType = (type, required, label) => {
  const phoneValidation = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  if (type === 'text' && required)
    return yup.string().required(`${label || 'This field'} is required.`);
  if (type === 'email' && required)
    return yup
      .string()
      .email('Invalid email address.')
      .required(`${label || 'This field'} is required.`);
  if (type === 'email') return yup.string().email('Invalid email address.');
  if (type === 'number' && required)
    return yup
      .number('Invalid number.')
      .required(`${label || 'This field'} is required.`);
  if (type === 'number') return yup.number('Invalid number.');
  if (type === 'url' && required)
    return yup
      .string('Invalid URL')
      .required(`${label || 'This field'} is required.`);
  if (type === 'url') return yup.string('Invalid URL');
  if (type === 'password' && required)
    return yup
      .string()
      .min(8, `${label} must be at least 8 characters.`)
      .matches(/[A-Z]/g, `${label} must include a capital letter.`)
      .matches(/[a-z]/g, `${label} must include a lowercase letter.`)
      .matches(/[0-9]/g, `${label} must include a number.`)
      .required(`${label || 'This field'} is required.`);
  if (type === 'tel' && required)
    return yup
      .string()
      .matches(phoneValidation, 'Invalid phone number.')
      .required(`${label || 'This field'} is required.`);
  if (type === 'tel')
    return yup.string().matches(phoneValidation, 'Invalid phone number.');
};

// created Input so that the proptypes can be assigned to this component.
const Input = props => <InformedInput {...props} />;

Input.propTypes = {
  /** Specifies input type */
  type: PropTypes.oneOf(['text', 'email', 'tel', 'number', 'url', 'password'])
    .isRequired,
  /** Sets the input field name (required by Informed) */
  field: PropTypes.string.isRequired,
  /** Sets the label text  */
  label: PropTypes.string,
  /** Sets the placeholder text  */
  placeholder: PropTypes.string,
  /** Sets the error message for the input - and sets input to error state  */
  errorMessage: PropTypes.string,
  /** Sets the width of the input - by default the input is 100% width  */
  width: PropTypes.number,
  /** Sets the max-width of the input */
  maxWidth: PropTypes.number,
  /** Disables input */
  disabled: PropTypes.bool,
  /** Makes input a required field */
  required: PropTypes.bool,
  /** Highlights the field with a dark outline by default */
  featured: PropTypes.bool,
  /** Default value on the input */
  defaultValue: PropTypes.string,
  /** Custom pattern */
  pattern: PropTypes.string,
  autocomplete: PropTypes.string,
};

Input.defaultProps = {
  disabled: false,
  required: false,
  featured: false
};

export default Input;
