import React from 'react';
import { createContainer } from 'unstated-next';
import useAxios, { simplyAxios } from 'hooks/useAxios';
import * as Sentry from '@sentry/browser';
import Hubspot from 'helpers/hubspot';
import md5 from 'md5';
import { ACCOUNT_CREATION } from 'constants/impactTrackingEvents';
import {
  cancelGooglePrompt,
  GoogleCredential,
  initializeGoogleSignIn,
  signOutGoogle,
} from 'utils/googleLogin/utils';
import { gtmDataLayer } from 'utils/gtm';
import Cookies from 'universal-cookie';
import { swallowAndRecover } from 'utils/utils';

interface User {
  readonly id: number;
  readonly external_id: number;
  readonly email: string;
  readonly last_name: string;
  readonly first_name: string;
  readonly permissions: any[];
  readonly username: string;
  readonly address: string | null;
  readonly city: string | null;
  readonly region: string | null;
  readonly postal_code: string | null;
  readonly country: string | null;
  readonly phone: any;
  readonly organization_name: string | null;
  readonly non_profit: boolean;
  readonly image: any;
  readonly isOwner: boolean;
  readonly canOwnerSeeManagementFeeModal: boolean;
  readonly isCollectionAdmin: boolean;
  readonly isFilmmakerAdmin: boolean;
  readonly isFilmmakerManager: boolean;
  readonly isAmbassador: boolean;
  readonly canAccessUnwatermarked: boolean;
  readonly active_occupation: any;
  readonly last_footage_research_request_submission_date: any;
  readonly in_free_clips_promotion: boolean;
  readonly in_edit_fest_promotion: boolean;
  readonly free_clips: {
    readonly data: any[];
  };
  readonly max_free_clips_count: number;
  readonly footage_research_request_submitted: boolean;
  readonly hasRegisteredToday: boolean;
  readonly promotions: any[];
  readonly roles: string[];
  readonly theme: string;
  readonly can_set_password: boolean;
}

export const checkIfUserRequiresProfileCompletion = (user: User | null) =>
  !!user && !user.active_occupation;

let authedUser: User | null = null;

const useAuth = () => {
  let [{ data, ...response }, request] = useAxios({ baseURL: '/auth' });
  let [, updateUserProfile] = useAxios();
  let [user, setUser] = React.useState<User | null>(null);
  let [initialFetchComplete, setInitialFetchComplete] = React.useState(false);
  const [googleCredential, setGoogleCredential] = React.useState('');
  const [accountIsLinked, setAccountIsLinked] = React.useState(false);
  const [isLoggingOut, setIsLoggingOut] = React.useState(false);

  const fetchAndSetGoogleCreds = async () => {
    const authRes = await fetchGoogleAccountStatus();

    if (!authRes?.has_google_account_linked) {
      setAccountIsLinked(false);
      return;
    }
    const { has_google_account_linked } = authRes;
    setAccountIsLinked(has_google_account_linked);

    if (has_google_account_linked && !googleCredential) {
      initializeGoogleSignIn((credential: GoogleCredential) => {
        setGoogleCredential(credential);
      });
    }
  };

  const authenticate = React.useCallback(() => {
    const apiCall = async () => {
      if (response.notAsked) {
        const { data: userData } = await request.get();
        setUser(userData);
        if (!initialFetchComplete) setInitialFetchComplete(true);

        fetchAndSetGoogleCreds();

        userData.id &&
          //@ts-ignore
          window.ire('identify', {
            customerId: userData.id,
            customerEmail: userData.email,
          });

        if (initialFetchComplete && userData.id) {
          Sentry.setUser({
            id: userData.id,
            email: userData.email,
            username: userData.username,
          });
        }
      }
    };
    apiCall();
  }, [initialFetchComplete, request, response.notAsked, user]);

  const fetchUser = async () => {
    const { data: userData } = await simplyAxios().get('/auth');
    void setUser(userData);
    if (!initialFetchComplete) void setInitialFetchComplete(true);
    fetchAndSetGoogleCreds();
    if (initialFetchComplete && userData.id) {
      void Sentry.setUser({
        id: userData.id,
        email: userData.email,
        username: userData.username,
      });
    }
    return userData;
  };

  const updateUser = fetchUser;

  const completeLogin = (result) => {
    const userData = result.data;
    if (!userData) return Promise.reject(result);
    if ('registration' in userData || 'challenge' in userData)
      return Promise.resolve(result);

    void setUser(userData);

    //@ts-ignore
    void window.ire('identify', {
      customerId: userData.id,
      customerEmail: userData.email,
    });

    void Sentry.setUser({
      id: userData.id,
      email: userData.email,
      username: userData.username,
    });

    return Promise.resolve(result);
  };

  const login = async ({
    email: login,
    password,
    remember,
    cart_hash = window.localStorage.getItem('cart_hash') || null,
  }) => {
    const result = await simplyAxios({ baseURL: '/auth' })
      .post('/login', {
        login,
        password,
        remember,
        ...(cart_hash && { cart_hash }),
      })
      .then(completeLogin);

    fetchAndSetGoogleCreds();

    return result;
  };

  const signInWithGoogle = ({
    google_credential,
  }: {
    readonly google_credential: GoogleCredential;
  }) => {
    setGoogleCredential(google_credential);
    return simplyAxios({ baseURL: '/auth' })
      .post('/google/auth/callback', {
        google_credential,
        ...getRegistrationData(),
      })
      .then(completeLogin);
  };

  const getRegistrationData = () => ({
    pageUrl: `${import.meta.env.VITE_APP_URL}${window.location.pathname}`,
    pageName: window.document.title,
    hutk: Hubspot.getCookieData(),
    ...getCartHash(),
  });

  const getCartHash = () => ({
    cart_hash: window.localStorage.getItem('cart_hash'),
  });

  const completeRegistration = (result) => {
    const userData = result.data;
    if (!userData) return Promise.reject(result);

    if ('registration' in userData || 'challenge' in userData)
      return Promise.resolve(result);

    void setUser(userData);

    void gtmDataLayer({
      event: 'registerFormSubmission',
      page: window.location.pathname,
      response: data,
    });

    //@ts-ignore
    void window.ire(
      'trackConversion',
      ACCOUNT_CREATION,
      {
        orderId: userData.id.toString(),
        customerId: userData.id.toString(),
        customerEmail: md5(userData.email),
      },
      {
        verifySiteDefinitionMatch: true,
      },
    );

    void Sentry.setUser({
      id: userData.id,
      email: userData.email,
      username: userData.username,
    });

    return Promise.resolve(result);
  };

  const register = ({ email, password }) =>
    simplyAxios({ baseURL: '/auth' })
      .post('/register', {
        email,
        password,
        password_again: password,
        activate: true,
        ...getRegistrationData(),
      })
      .then(completeRegistration);

  const registerWithGoogle = ({
    google_credential,
    referral_code = new Cookies().get('filmsupply.registrationReferralCode'),
  }) => {
    setGoogleCredential(google_credential);
    return simplyAxios({ baseURL: '/auth' })
      .post('/google/auth/callback', {
        google_credential,
        referral_code,
        ...getRegistrationData(),
      })
      .then(completeRegistration);
  };

  let logout = async () => {
    /**
     * setIsLoggingOut is used to hide the Google button when logging out, in order to prevent it
     * from automatically reauthing when on one of the AUTO_LOGIN_ROUTES routes
     * defined in 'src/utils/googleLogin/utils.ts'
     */
    setIsLoggingOut(true);

    setTimeout(async () => {
      cancelGooglePrompt();
      if (!!googleCredential) {
        signOutGoogle();
        setGoogleCredential('');
      }

      const { data } = await request.post('/logout');

      if (data.success) {
        authedUser = null;
        Sentry.setUser(null);
        localStorage.removeItem('cart_hash');
        window.location.assign('/login');
      }
    }, 100);
  };

  let masqueradeLogout = async () => {
    await request.get('/logout/masquerade');
    document.cookie =
      'hubspotutk=;Path=/;Domain=.filmsupply.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    document.cookie =
      '__hstc=;Path=/;Domain=.filmsupply.com;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    window.location.reload();
  };

  let setTheme = async (theme) => {
    const refreshPages = ['/clips', '/account/licenses'];
    if (refreshPages.includes(window.location.pathname)) {
      const { data } = await updateUserProfile.put('/user/profile/theme', {
        theme,
      });
      if (data.success) window.location.reload();
    } else {
      void setUser({ ...user!, theme });
      localStorage.setItem('theme', theme);
      await updateUserProfile.put('/user/profile/theme', { theme });
    }
  };

  const setAccountPassword = ({ password, password_confirm }) =>
    simplyAxios().post('/user/password', { password, password_confirm });

  const linkGoogleAccount = ({ challenge_token, password }) =>
    simplyAxios({ baseURL: '/auth' }).post(
      '/google/auth/password-confirmation',
      {
        challenge_token,
        password,
        ...getCartHash(),
      },
    );

  const connectGoogleAccount = ({ google_credential }) => {
    setGoogleCredential(google_credential);
    return simplyAxios({ baseURL: '/auth' }).post('/google/connect/callback', {
      google_credential,
    });
  };

  const disconnectGoogleAccount = () =>
    simplyAxios({ baseURL: '/auth' }).delete('/google/disconnect');

  const fetchGoogleAccountStatus = () =>
    simplyAxios({ baseURL: '/auth' })
      .get('/google/account')
      .then(({ data }) => data);

  const shouldShowManualOptIt = () =>
    simplyAxios()
      .get('/geoip')
      .then(
        (result: any) =>
          result.success === false || result.data?.requires_opt_in === true,
      )
      .catch(() => true); // Err on the side of caution.

  const updateEmailPreferences = (
    userId: User['id'],
    {
      email,
      preferences,
    }: { readonly email: User['email']; readonly preferences: string[] },
  ) =>
    simplyAxios()
      .post(`/user/notification-preferences/register-page/${userId}`, {
        email,
        preferences,
      })
      .catch(swallowAndRecover);

  const requiresProfileCompletion = !!user && !user.active_occupation;

  /**
   * Track HubSpot user on authentication
   */
  const cacheAuthedUser = authedUser === null && user?.id !== undefined;
  if (cacheAuthedUser) {
    authedUser = user;
    const { id, email } = user!;
    Hubspot.identifyUser(id, email);
  }

  return {
    user,
    response,
    authenticate,
    updateUser,
    login,
    register,
    isLoggingOut,
    logout,
    masqueradeLogout,
    initialFetchComplete,
    setTheme,
    signInWithGoogle,
    googleCredential,
    setGoogleCredential,
    fetchAndSetGoogleCreds,
    accountIsLinked,
    setAccountIsLinked,
    registerWithGoogle,
    linkGoogleAccount,
    connectGoogleAccount,
    disconnectGoogleAccount,
    fetchGoogleAccountStatus,
    setAccountPassword,
    requiresProfileCompletion,
    shouldShowManualOptIt,
    updateEmailPreferences,
  };
};

export type Auth = ReturnType<typeof useAuth>;

export default createContainer(useAuth);
