//@ts-nocheck
import * as yup from 'yup';
import { withFormState } from 'informed';
import Icon from 'components/Icon/Icon';
import {
  Container,
  InputWrapper,
  Label,
  Error,
  TextInput
} from './styles/StyledFormComponents';

const InformedInput = withFormState(
  ({
    type,
    field,
    width,
    maxWidth,
    className,
    formState,
    required = false,
    errorMessage,
    defaultValue,
    validationSchema,
    disabled = false,
    isAPIVerified = false,
    ...rest
  }: Input) => {

    const restProps = { ...rest, featured: rest.featured || false }
    const schema =
      validationSchema || getValidationType(type, required, restProps.label);
    const { label, ...labelProps } = restProps;
    const isShowError = !!errorMessage || !!formState.errors[field];
    return (
      <Container>
        <InputWrapper
          isDirty={formState.values[field]}
          disabled={disabled}
          isShowError={isShowError}
        >
          <TextInput
            type={type}
            {...restProps}
            field={field}
            isDirty={formState.values[field]}
            validateOnBlur
            validateOnChange
            validationSchema={schema}
            isShowError={isShowError}
            initialValue={defaultValue}
            disabled={disabled}
          />

          {restProps.label && (
            <Label className="inputLabel" {...labelProps} disabled={disabled}>
              {restProps.label}
            </Label>
          )}

          {isAPIVerified &&
            !disabled &&
            !isShowError &&
            formState.values[field] && (
              <div style={{ paddingRight: 15 }}>
                <Icon name="inputTick" color="blue" size={12} />
              </div>
            )}

          {isShowError && (
            <div style={{ paddingRight: 15 }}>
              <Icon name="inputError" color="red" size={12} />
            </div>
          )}
        </InputWrapper>
        {isShowError && (
          <Error>{errorMessage || formState.errors[field]}</Error>
        )}
      </Container>
    );
  }
);

//Simple validation to check against input types
const getValidationType = (type, required, label) => {
  const phoneValidation = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  if (type === 'text' && required)
    return yup.string().required(`${label || 'This field'} is required.`);
  if (type === 'email' && required)
    return yup
      .string()
      .email('Invalid email address.')
      .required(`${label || 'This field'} is required.`);
  if (type === 'email') return yup.string().email('Invalid email address.');
  if (type === 'number' && required)
    return yup
      .number('Invalid number.')
      .required(`${label || 'This field'} is required.`);
  if (type === 'number') return yup.number('Invalid number.');
  if (type === 'url' && required)
    return yup
      .string('Invalid URL')
      .required(`${label || 'This field'} is required.`);
  if (type === 'url') return yup.string('Invalid URL');
  if (type === 'password' && required)
    return yup
      .string()
      .min(8, `${label} must be at least 8 characters.`)
      .matches(/[A-Z]/g, `${label} must include a capital letter.`)
      .matches(/[a-z]/g, `${label} must include a lowercase letter.`)
      .matches(/[0-9]/g, `${label} must include a number.`)
      .required(`${label || 'This field'} is required.`);
  if (type === 'tel' && required)
    return yup
      .string()
      .matches(phoneValidation, 'Invalid phone number.')
      .required(`${label || 'This field'} is required.`);
  if (type === 'tel')
    return yup.string().matches(phoneValidation, 'Invalid phone number.');
};

// created Input so that the proptypes can be assigned to this component.
const Input = (props: Input) => <InformedInput {...props} />;

interface Input {
  readonly type: 'text' | 'email' | 'tel' | 'number' | 'url' | 'password'  // Specifies input type.
  readonly field: string            // Sets the input field name (required by Informed).
  readonly label?: string           // Sets the label text.
  readonly placeholder?: string     // Sets the placeholder text.
  readonly errorMessage?: string    // Sets the error message for the input - and sets input to error state.
  readonly width?: number           // Sets the width of the input - by default the input is 100% width.
  readonly maxWidth?: number        // Sets the max-width of the input.
  readonly disabled?: boolean       // Disables input.
  readonly required?: boolean       // Makes input a required field.
  readonly featured?: boolean       // Highlights the field with a dark outline by default.
  readonly defaultValue?: string    // Default value on the input.
  readonly isAPIVerified?: boolean
  readonly validationSchema?: any
  readonly [key: string]: any
}

export default Input;
