import React from 'react';
import PropTypes from 'prop-types';
import * as Styled from './styles/StyledLayout';

import Header from 'components/Header/Header';
import Footer from 'components/Footer';
import { useLocation, matchPath } from 'react-router';

const pagesWithoutHeader = [
  '/login',
  '/register',
  '/forgot-password',
  '/reset-password/:hash',
  '/always-sunny',
];

const pagesWithoutFooter = [
  '/login',
  '/register',
  '/forgot-password',
  '/reset-password/:hash',
  '/cart',
  '/always-sunny',
];

const Layout = ({ children }) => {
  let location = useLocation();

  const hasFooter = () => {
    return !matchPath(location.pathname, {
      path: pagesWithoutFooter,
      exact: true,
      strict: false,
    });
  };

  const hasHeader = () => {
    return !matchPath(location.pathname, {
      path: pagesWithoutHeader,
      exact: true,
      strict: false,
    });
  };

  return (
    <Styled.Layout>
      {hasHeader() && <Header onlyLogo={!hasFooter()} />}
      {children}
      {hasFooter() && <Footer />}
    </Styled.Layout>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  location: PropTypes.object,
};

export const PageContent = ({ children, ...props }) => (
  <Styled.PageContent {...props}>{children}</Styled.PageContent>
);

PageContent.propTypes = {
  children: PropTypes.node,
};

export const BasePageContent = ({ children }) => (
  <Styled.BasePageContent>{children}</Styled.BasePageContent>
);

BasePageContent.propTypes = {
  children: PropTypes.node,
};

export default Layout;
