import React from 'react';

const Login = React.lazy(() => import('pages/Login'));
const Register = React.lazy(() => import('pages/Register'));
const ForgotPassword = React.lazy(() => import('pages/ForgotPassword'));
const ResetPassword = React.lazy(() => import('pages/ResetPassword'));
const AccountDetails = React.lazy(() => import('pages/AccountDetails'));
const AccountLicenses = React.lazy(() => import('pages/AccountLicenses'));
const Home = React.lazy(() => import('pages/Home'));
const About = React.lazy(() => import('pages/About'));
const Browse = React.lazy(() => import('pages/Browse'));
const Cart = React.lazy(() => import('pages/Cart'));
const CartSuccess = React.lazy(() => import('pages/CartSuccess'));
const Category = React.lazy(() => import('pages/Category'));
const CCPAPrivacyPolicy = React.lazy(() => import('pages/CCPAPrivacyPolicy'));
const Clip = React.lazy(() => import('pages/Clip'));
const Collection = React.lazy(() => import('pages/Collection'));
const Collections = React.lazy(() => import('pages/Collections'));
const Contact = React.lazy(() => import('pages/Contact'));
const Faqs = React.lazy(() => import('pages/Faqs'));
const Filmmaker = React.lazy(() => import('pages/Filmmaker'));
const Filmmakers = React.lazy(() => import('pages/Filmmakers'));
const LicenseTerms = React.lazy(() => import('pages/LicenseTerms'));
const Maintenance = React.lazy(() => import('pages/Maintenance'));
const MobileCheckout = React.lazy(() => import('pages/MobileCheckout'));
const NotFound = React.lazy(() => import('pages/404'));
const NotificationPreferences = React.lazy(
  () => import('pages/NotificationPreferences'),
);
const EditFest = React.lazy(() => import('pages/EditFest'));
const PaymentMethods = React.lazy(() => import('pages/PaymentMethods'));
const Pricing = React.lazy(() => import('pages/Pricing'));
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicy'));
const Project = React.lazy(() => import('pages/Project'));
const Projects = React.lazy(() => import('pages/Projects'));
const Scenes = React.lazy(() => import('pages/Scenes'));
const Services = React.lazy(() => import('pages/Services'));
const Shoots = React.lazy(() => import('pages/Shoots'));
const Sitemap = React.lazy(() => import('pages/Sitemap'));
const Terms = React.lazy(() => import('pages/Terms'));
const LoginDetails = React.lazy(() => import('pages/UpdatePassword'));
const FootageResearch = React.lazy(() => import('pages/FootageResearch'));
const RequestFootageResearch = React.lazy(
  () => import('components/RequestFootageResearch'),
);
const PopularSearches = React.lazy(() => import('pages/PopularSearches'));
const AlwaysSunny = React.lazy(() => import('pages/AlwaysSunny'));

export {
  Login,
  Register,
  ForgotPassword,
  ResetPassword,
  AccountDetails,
  AccountLicenses,
  Home,
  About,
  Browse,
  Cart,
  CartSuccess,
  Category,
  CCPAPrivacyPolicy,
  Clip,
  Collection,
  Collections,
  Contact,
  Faqs,
  Filmmaker,
  Filmmakers,
  LicenseTerms,
  Maintenance,
  MobileCheckout,
  NotFound,
  NotificationPreferences,
  EditFest,
  PaymentMethods,
  Pricing,
  PrivacyPolicy,
  Project,
  Projects,
  Scenes,
  Services,
  Shoots,
  Sitemap,
  Terms,
  FootageResearch,
  RequestFootageResearch,
  LoginDetails,
  PopularSearches,
  AlwaysSunny,
};
