import { Link } from 'react-router-dom';
import React from 'react';
import Icon from 'components/Icon/Icon';
import { Select, Option } from 'components/PopOver/PopOver';
import Flex from 'components/Flex/Flex';
import * as Styled from './styles/StyledNavbar';
import { useTheme } from '@emotion/react';
import Auth from 'unstated/Auth';
import Avatar from 'components/Avatar/Avatar';
import { HEADER_HEIGHT } from 'components/Header/Header';

const OPTION_STYLE = {
  height: 56,
  width: '100%',
};

const MobileAccountMenu = ({ isMobileMenuOpen, toggleOffMobileMenu }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { user, logout, masqueradeLogout } = Auth.useContainer();
  const theme = useTheme();

  React.useEffect(() => {
    if (isMobileMenuOpen) {
      setIsOpen(false);
    }
  }, [isMobileMenuOpen]);

  React.useEffect(() => {
    if (isOpen) {
      toggleOffMobileMenu();
    }
  }, [isOpen, toggleOffMobileMenu]);

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    handleToggleMenu();
    logout();
  };

  const handleMasqueradeLogout = () => {
    masqueradeLogout();
    logout();
  };

  const canViewDashboard =
    user.isFilmmakerAdmin ||
    user.isCollectionAdmin ||
    user.isFilmmakerManager ||
    user.isOwner;

  return (
    <>
      <Styled.AccountMobile
        iconColor="contrast3"
        size="lg"
        nopad
        color={user.masquerade ? 'purple' : 'contrast'}
        hoverColor={'transparent'}
        css={{ position: 'relative' }}
        onClick={handleToggleMenu}
      >
        <Avatar user={user} />
      </Styled.AccountMobile>

      {isOpen && (
        <Styled.MenuContainer top={HEADER_HEIGHT.NAVBAR}>
          <Styled.ProfileRow>
            <Styled.ProfileRowLeft>
              <Styled.BlackSpacer />

              <Avatar user={user} />
              <Flex column>
                <Styled.UserName>
                  {user.first_name} {user.last_name}
                </Styled.UserName>
                <Styled.Email>{user.email}</Styled.Email>
              </Flex>
            </Styled.ProfileRowLeft>
            <Flex css={{ color: 'red' }}>
              <Icon name="check" color="primary" hover="primary" size={12} />
            </Flex>
          </Styled.ProfileRow>
          <Styled.SpacerContainer>
            <Styled.MenuSpacer />
          </Styled.SpacerContainer>

          <Select
            bgColor="white"
            css={{
              zIndex: 5,
              right: 0,
              width: '100%',
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6,
              fontSize: 16,
              fontWeight: 500,
            }}
            marginBottom={0}
          >
            <Option
              onClick={handleToggleMenu}
              css={OPTION_STYLE}
              as={Link}
              to={`/account/details`}
            >
              Account Details
            </Option>

            <Option
              onClick={handleToggleMenu}
              css={OPTION_STYLE}
              as={Link}
              to={`/account/password`}
            >
              Login Details
            </Option>
            <Option
              onClick={handleToggleMenu}
              css={OPTION_STYLE}
              as={Link}
              to={`/account/notification-preferences`}
            >
              Notifications
            </Option>
            <Option
              onClick={handleToggleMenu}
              css={OPTION_STYLE}
              as={Link}
              to={`/projects`}
            >
              My Projects
            </Option>
            <Option
              onClick={handleToggleMenu}
              css={OPTION_STYLE}
              as={Link}
              to={`/account/licenses`}
            >
              My Licenses
            </Option>

            {canViewDashboard && (
              <Option
                onClick={handleToggleMenu}
                css={OPTION_STYLE}
                as="a"
                target="_self"
                href={`${import.meta.env.VITE_APP_URL}/dashboard/footage-manager/shoots`}
              >
                Dashboard
              </Option>
            )}
            <Option
              onClick={handleToggleMenu}
              css={OPTION_STYLE}
              as={Link}
              to={`/account/payment-methods`}
            >
              Payment Methods
            </Option>

            <Styled.SpacerContainer
              css={{
                paddingBottom: 8,
              }}
            >
              <Flex
                flexDirection="column"
                css={{
                  height: 12,
                  width: '100%',
                  borderBottomWidth: 1,
                  borderBottomColor: theme.color.contrast2,
                  borderBottomStyle: 'solid',
                }}
              />
            </Styled.SpacerContainer>
            {user.isAmbassador && (
              <Option
                onClick={handleToggleMenu}
                css={OPTION_STYLE}
                as="a"
                href={`https://app.impact.com/abe/FM/login.user`}
              >
                Affiliates
              </Option>
            )}

            {user.masquerade ? (
              <Option css={OPTION_STYLE} onClick={handleMasqueradeLogout}>
                <Icon name="close" size="xs" color="purple" />{' '}
                <span
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: 70,
                    textOverflow: 'ellipsis',
                    display: 'inline-block',
                    marginLeft: 5,
                  }}
                >
                  {user.masquerade.client_username}
                </span>
              </Option>
            ) : (
              <Option css={OPTION_STYLE} onClick={handleLogout}>
                Logout
              </Option>
            )}
          </Select>
        </Styled.MenuContainer>
      )}
    </>
  );
};

export default MobileAccountMenu;
