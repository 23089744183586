import * as pages from './pageComponents';

/**
 * Add restricted roles to "restrict" array
 *
 * User Roles:
 * 1 - Member Basic
 * 2 - Admin
 * 3 - Owner
 */

/**
 * Add your page component to pageComponents.js
 */

const routes = [
  {
    name: 'Home',
    component: pages.Home,
    path: '/',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'About',
    component: pages.About,
    path: '/about',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Contact',
    component: pages.Contact,
    path: '/contact',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Categories',
    component: pages.Sitemap,
    path: '/category',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Category',
    component: pages.Category,
    path: '/category/:slug',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'LicenseTerms',
    component: pages.LicenseTerms,
    path: '/license-terms',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Terms',
    component: pages.Terms,
    path: '/terms-and-conditions',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Pricing',
    component: pages.Pricing,
    path: '/pricing',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Filmmakers',
    component: pages.Filmmakers,
    path: '/filmmakers',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Filmmaker',
    component: pages.Filmmaker,
    path: '/filmmakers/:slug/:id(\\d+)',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Footage Research',
    component: pages.FootageResearch,
    path: '/footage-research',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Licenses',
    component: pages.AccountLicenses,
    path: '/account/licenses',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/login',
    options: { exact: true, authenticate: true },
  },
  {
    name: 'Account Details',
    component: pages.AccountDetails,
    path: '/account/details',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/login',
    options: { exact: true, authenticate: true },
  },
  {
    name: 'Account Details Email Confirm',
    component: pages.AccountDetails,
    path: '/account/details/:slug',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/login',
    options: { exact: true, authenticate: true },
  },
  {
    name: 'Payment Methods',
    component: pages.PaymentMethods,
    path: '/account/payment-methods',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/login',
    options: { exact: true, authenticate: true },
  },
  {
    name: 'Notification Preferences',
    component: pages.NotificationPreferences,
    path: '/account/notification-preferences',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/login',
    options: { exact: true, authenticate: true },
  },
  {
    name: 'Notification Preferences',
    component: pages.NotificationPreferences,
    path: '/account/notification-preferences/:hash',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/login',
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Login Details',
    component: pages.LoginDetails,
    path: '/account/password',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/login',
    options: { exact: true, authenticate: true },
  },
  {
    name: 'EditFest',
    component: pages.EditFest,
    path: '/account/editfest',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/login',
    options: { exact: true, authenticate: true },
  },
  {
    name: 'Login',
    component: pages.Login,
    path: '/login',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Register',
    component: pages.Register,
    path: '/register',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Forgot Password',
    component: pages.ForgotPassword,
    path: '/forgot-password',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Reset Password',
    component: pages.ResetPassword,
    path: '/reset-password/:hash',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Restricted Page',
    component: pages.AccountDetails,
    path: '/restricted',
    userConditionals: null,
    permissions: {
      restrict: [2, 3, 6],
    },
    permissionRedirect: '/',
    options: { exact: true, authenticate: true },
  },
  {
    name: 'Browse',
    component: pages.Browse,
    path: '/clips',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/',
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Browse Search',
    component: pages.Browse,
    path: '/stock-footage/:searchTerm',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/',
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Popular Searches',
    component: pages.PopularSearches,
    path: '/stock-footage',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/',
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Conditional Page',
    component: pages.Home,
    path: '/conditional',
    userConditionals: {
      completedVerticals: true,
    },
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/conditional-redirect',
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Cart',
    component: pages.Cart,
    path: '/cart',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Collections',
    component: pages.Collections,
    path: '/collections',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Collection',
    component: pages.Collection,
    path: '/collections/:slug/:id(\\d+)',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Cart Succes',
    component: pages.CartSuccess,
    path: '/cart/success',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Mobile Checkout Page',
    component: pages.MobileCheckout,
    path: '/checkout',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Pricing',
    component: pages.Pricing,
    path: '/pricing',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Faqs',
    component: pages.Faqs,
    path: '/faq',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Projects',
    component: pages.Projects,
    path: '/projects',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: '/clips',
    options: { exact: true, authenticate: true },
  },
  {
    name: 'Project',
    component: pages.Project,
    path: '/project/:slug/:id(\\d+)',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Shoots',
    component: pages.Shoots,
    path: '/shoots/:slug/:id(\\d+)',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Scenes',
    component: pages.Scenes,
    path: '/shoots/:collection_slug/:collection_id(\\d+)/scenes/:slug/:id(\\d+)',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Services',
    component: pages.Services,
    path: '/services',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Clip Detail Page',
    component: pages.Clip,
    path: '/clips/:slug/:id(\\d+)',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: false, authenticate: false },
  },
  {
    name: 'Privacy Policy',
    component: pages.PrivacyPolicy,
    path: '/privacy-policy',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'CCPA Privacy Policy',
    component: pages.CCPAPrivacyPolicy,
    path: '/privacy-policy/ccpa',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Maintenance',
    component: pages.Maintenance,
    path: '/maintenance',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Footage Research',
    component: pages.FootageResearch,
    path: '/footage-research',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'Always Sunny Landing',
    component: pages.AlwaysSunny,
    path: '/always-sunny',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: true, authenticate: false },
  },
  {
    name: 'NotFound',
    component: pages.NotFound,
    path: '*',
    userConditionals: null,
    permissions: {
      restrict: [],
    },
    permissionRedirect: null,
    options: { exact: false, authenticate: false },
  },
];

export default routes;
