import styled from '@emotion/styled';

export const Accent = styled.span(
  ({ theme }) => ({
    color: theme.color.primary,
  })
);

export const Section = styled.div({
  width: '100%',
});

export const Heading = styled.div(
  ({ theme }) => ({
    fontSize: '20px',
    fontWeight: 700,
    fontStyle: 'normal',
    lineHeight: 'normal',
    color: theme.color.staticGrey4,
  })
);

export const Paragraph = styled.p(
  ({ theme }) => ({
    color: theme.color.staticGrey4,
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '18px',
    margin: 0,
    textAlign: 'center' as const,
  })
);

export const Headline = styled.p(
  ({ theme }) => ({
    color: theme.color.staticGrey4,
    fontSize: '14px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '18px',
    marginTop: '12px',
    marginBotttom: '40px',
  })
);

export const OrContainer = styled.div({
  display: 'flex',
  width: '100%',
  gap: '8px',
  margin: '24px 0',
  alignItems: 'center',
});

export const LineContainer = styled.div({
  flex: 1,
});

export const Line = styled.hr({
  height: '1px',
  border: 0,
  background: '#E2E3E4',
});

export const Or = styled.div(
  ({ theme }) => ({
    color: theme.color.primary,
    fontFamily: 'Proxima Nova',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  }));
