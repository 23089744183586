import React from 'react';
import { createContainer } from 'unstated-next';
import useAxios from 'hooks/useAxios';
import Auth from 'unstated/Auth';

const useProjects = () => {
  const { user } = Auth.useContainer();
  const [
    { data: userProjects, ...projectsResponse },
    getProjectsApi
  ] = useAxios();
  const [
    { data: editedProject, ...editProjectResponse },
    editProjectApi
  ] = useAxios();
  const [projects, setProjects] = React.useState([]);
  const [selectedClip, setSelectedClip] = React.useState(null);
  const [popoverState, setPopoverState] = React.useState({
    open: false,
    project: null,
    clip: null
  });
  const [actionCommitted, setActionCommitted] = React.useState({
    action: null,
    value: null
  });

  const fetchProjects = React.useCallback(() => {
    getProjectsApi.get('/wishlists', { params: { favorites: 1, limit: 200 } });
  }, [getProjectsApi]);

  React.useEffect(() => {
    if (user?.id) fetchProjects();
  }, [user?.id, fetchProjects]);

  React.useEffect(() => {
    if (editProjectResponse.success) fetchProjects();
  }, [editProjectResponse.success, fetchProjects]);

  React.useEffect(() => {
    if (projectsResponse.success) {
      setProjects(userProjects);
    }
  }, [projectsResponse.success, userProjects]);

  const triggerPopover = (project, clip) => {
    setPopoverState({ open: true, project, clip });
    setTimeout(() => {
      setPopoverState({ open: false, project: null, clip: null });
    }, 5000);
  };

  const selProjectClips = React.useCallback(
    id => projects.find(proj => proj.id === Number(id)),
    [projects]
  );

  const deleteProject = ({ id }) => editProjectApi.delete(`/wishlists/${id}`);

  const editProject = React.useCallback(
    ({ name, id }) => editProjectApi.put(`/wishlists/${id}`, { name }),
    [editProjectApi]
  );

  const addProject = async ({ name, clip }) => {
    if (clip) setSelectedClip({ clip_id: clip.id });
    const { data: project } = await editProjectApi.post('/wishlists', { name });
    const newProject = {
      name: project.name,
      id: project.id,
      slug: project.name
        .toLowerCase()
        .split(' ')
        .join('-')
    };
    if (clip && project.id) {
      triggerPopover(newProject, clip);
      return { project: newProject };
    }
    return newProject;
  };

  const addClipToProject = React.useCallback(
    async ({ project_id, clip_id }) => {
      await editProjectApi.post(`/wishlists/${project_id}/attach`, {
        clip_id: [clip_id]
      });
      setActionCommitted({ action: 'add', value: clip_id });
    },
    [editProjectApi]
  );

  const removeClipFromProject = React.useCallback(
    async (clip_id, project_id) => {
      await editProjectApi.delete(`/wishlists/${project_id}/detach`, {
        params: { clip_id }
      });
      setActionCommitted({ action: 'delete', value: clip_id });
    },
    [editProjectApi]
  );

  React.useEffect(() => {
    if (editProjectResponse.success && editedProject.id && selectedClip) {
      const clip_id = selectedClip;
      setSelectedClip(null);
      addClipToProject({ project_id: editedProject.id, clip_id });
    }
  }, [
    addClipToProject,
    editProjectResponse.success,
    editedProject,
    selectedClip
  ]);

  return {
    projects: { ...projectsResponse, data: projects },
    addProject,
    deleteProject,
    editProject,
    addClipToProject,
    popoverState,
    triggerPopover,
    fetchProjects,
    selProjectClips,
    removeClipFromProject,
    actionCommitted
  };
};

export default createContainer(useProjects);
