interface FilmsupplyLogo {
  readonly style?: Record<string, any>;
}

export const FilmsupplyLogo = ({ style = {} }: FilmsupplyLogo) =>
  <div css={style}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_115_22939)">
        <rect width="40" height="40" fill="white"/>
        <rect width="40" height="40" fill="#0E0F17"/>
      </g>
      <path d="M17.8017 28.701V21.5208H26.0706V18.2807H17.8017V14.6517H26.2498V11.4116H14.1665V28.701H17.8017Z" fill="white"/>
      <defs>
        <clipPath id="clip0_115_22939">
          <rect width="40" height="40" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  </div>
