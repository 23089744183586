import { useCallback, useEffect, useState } from 'react';
import { IdentityProvider } from '../IdentityProvider';
import Input from 'components/Forms/Input';
import Button, { TextLink } from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import baseForm from 'components/Forms/Form';
import Flex from 'components/Flex/Flex';
import { P2 } from 'components/Typography/Typography';
import {
  enteringPasswordSchema,
  PasswordInput,
} from 'components/Forms/PasswordInput';
import useMountEffect from 'hooks/useMountEffect';

export const StandardIdentityProvider: IdentityProvider = ({
  auth,
  viewIsLogin,
  notifySuccess,
  notifyFailure,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [showingManualOptIn, setShowingManualOptIt] = useState(false);
  const [loading, setLoading] = useState(false);

  const action = viewIsLogin ? auth.login : auth.register;

  const attemptLoginOrRegister = ({ email, password, remember, optin }) => {
    void setLoading(true);
    void setError(null);
    void action({ email, password, remember })
      .then(setNotificationPreferencesIfNecessary(optin))
      .then(({ data }) => {
        void notifySuccess?.(data);
      })
      .catch(({ errors }) => {
        void setError(
          (Object.values(errors)[0] as string) ||
            'An error has occurred. Please try again later.',
        );
        void notifyFailure?.(errors);
      })
      .finally(() => void setLoading(false));
  };

  const setNotificationPreferencesIfNecessary = useCallback(
    (optin) => async (result) => {
      if (viewIsLogin) return result; // Act only when registering a new user.
      const userData = result.data;
      const shouldUpdatePreferences = !showingManualOptIn || !!optin;

      if (shouldUpdatePreferences)
        void (await auth.updateEmailPreferences(userData.id, {
          // Await because further processing may depened on the changes to the user object.
          email: userData.email,
          preferences: [
            'email_filmsupply_films',
            'email_blog_updates',
            'email_marketing_updates',
          ],
        }));

      return result;
    },
    [viewIsLogin, showingManualOptIn],
  );

  void useMountEffect(
    () => void auth.shouldShowManualOptIt().then(setShowingManualOptIt),
  );

  const Form = baseForm(
    ({ formState: { invalid, values } }: { readonly formState: FormState }) => (
      <Flex column css={{ gap: '12px' }}>
        <Input
          inverted
          type="email"
          required
          field="email"
          label="Email"
          disabled={loading}
        />
        <PasswordInput
          inverted
          required
          field="password"
          label="Password"
          disabled={loading}
          validationSchema={enteringPasswordSchema}
        />
        {error !== null && <P2 color="red">{error}</P2>}

        {viewIsLogin && (
          <Flex
            justify="between"
            align="center"
            css={{ width: '100%', height: '24px' }}
          >
            <Flex align="center" css={{ gap: '4px' }}>
              <Checkbox field="remember" disabled={loading} />
              <P2 color="staticBlack">
                <label htmlFor="remember">Remember me</label>
              </P2>
            </Flex>

            <TextLink
              to="/forgot-password"
              color="contrast6"
              underline
              target="_blank"
            >
              Forgot Password
            </TextLink>
          </Flex>
        )}

        {!viewIsLogin && showingManualOptIn && (
          <Flex align="center" css={{ gap: '4px' }}>
            <Checkbox field="optin" disabled={loading} />
            <P2 color="staticBlack">
              <label htmlFor="optin">
                Stay up to date with films, footage, and filmmaking content.
              </label>
            </P2>
          </Flex>
        )}

        <Button
          full
          css={{ fontWeight: 700 }}
          disabled={loading || invalid || !values.email || !values.password}
          onClick={() =>
            void attemptLoginOrRegister({
              email: values.email,
              password: values.password,
              remember: values.remember,
              optin: values.optin,
            })
          }
        >
          {viewIsLogin && !loading
            ? 'Login'
            : viewIsLogin && loading
              ? 'Logging you in…'
              : !viewIsLogin && !loading
                ? 'Create Free Account'
                : /* !viewIsLogin && loading */ 'Creating account…'}
        </Button>
      </Flex>
    ),
  );

  return <Form />;
};
