import React, { Fragment, useRef, useEffect } from 'react';
import lightTheme from 'themes/lightTheme';
import * as Styled from './styles/StyledCheckbox';
const COLORS = Object.keys(lightTheme.color);

type Props = {
  field?: string;
  checked?: boolean;
  color?: keyof typeof COLORS;
  [x: string]: any;
};

const Checkbox = ({ field, checked, color, ...restProps }: Props) => {
  const inputRef = useRef<HTMLInputElement | any>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.checked = checked as boolean; // Now directly modifying the DOM element's checked property
    }
  }, [checked]);

  return (
    <Styled.CheckboxWrapper className={restProps.className}>
      {field ? (
        <Fragment>
          <Styled.CheckboxInput
            id={field}
            // @ts-ignore: TODO: can-o-worms wrt <Styled.CheckboxInput>. Not enough time in this sprint to sort out.
            field={field}
            color={color as string}
            {...restProps}
            ref={inputRef}
          />
          <label htmlFor={field} />
        </Fragment>
      ) : (
        <Fragment>
          <Styled.BaseCheckbox
            type="checkbox"
            id={restProps.id}
            checked={checked}
            color={color as string}
            {...restProps}
          />
          <label htmlFor={restProps.id} />
        </Fragment>
      )}
    </Styled.CheckboxWrapper>
  );
};
export default Checkbox;
