import styled from '@emotion/styled';
import isPropValid from '@emotion/is-prop-valid';
import { Text, TextArea as InformedTextArea } from 'informed';

const getBorderColor = ({
  disabled,
  isShowError,
  inverted,
  isDirty,
  theme
}) => {
  if (inverted)
    return theme.color[
      disabled ? 'transparent' : isShowError ? 'red' : 'primary'
    ];
  else
    return theme.color[
      disabled
        ? 'contrast1'
        : isShowError
        ? 'red'
        : isDirty
        ? 'contrast5'
        : 'contrast3'
    ];
};

const getLabelColor = ({ disabled, isShowError, inverted, theme }) => {
  if (inverted)
    return theme.color[
      disabled ? 'staticGrey4' : isShowError ? 'red' : 'primary'
    ];
  else
    return theme.color[
      disabled ? 'contrast1' : isShowError ? 'red' : 'staticGrey3'
    ];
};

const getBackgroundColor = ({ inverted, disabled, theme }) => {
  if (disabled) return theme.color.contrast1;
  if (inverted) return theme.color.contrast;
  else return theme.color[disabled ? 'staticGrey7' : 'staticWhite'];
};

export const Container = styled.div({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-end',
  gap: 3.5
});

const HEIGHT = 48;
export const InputWrapper = styled.div(
  {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: HEIGHT,
    width: '100%',
    'input[type=number]::-webkit-inner-spin-button': {
      appearance: 'none'
    },

    'input[type=number]::-webkit-outer-spin-button': {
      appearance: 'none'
    }
  },
  ({ disabled, isShowError, inverted, isDirty, theme }) => ({
    border: `1px solid ${getBorderColor({
      disabled,
      isShowError,
      inverted,
      isDirty,
      theme
    })}`,
    ':hover, :focus-within': {
      borderColor: disabled
        ? theme.color['contrast1']
        : theme.color[isShowError ? 'red' : 'blue']
    },
    backgroundColor: disabled ? theme.color['contrast1'] : 'transparent'
  })
);

const TOP_PADDING = 10;
const X_PADDING = 16;
const LABEL_FONT_SIZE = 12;
const LABEL_TOP_CENTER = `calc(${LABEL_FONT_SIZE}px + ${TOP_PADDING / 2}px)`;
const LABEL_TOP_UP = -1 * LABEL_FONT_SIZE + LABEL_FONT_SIZE / 2 - 1;
export const Label = styled.div(({ theme, disabled }) => ({
  fontSize: LABEL_FONT_SIZE,
  position: 'absolute',
  left: 15,
  top: LABEL_TOP_CENTER,
  pointerEvents: 'none',
  backgroundColor: disabled ? 'transparent' : 'white !important',
  transitionDuration: '0.15s',
  color: disabled ? theme.color['staticGrey4'] : theme.color.primary,
  padding: '0 3px'
}));

export const Error = styled.div(
  {
    display: 'flex',
    fontSize: 12,
    lineHeight: '14px',
    fontWeight: 400
  },
  ({ theme }) => ({
    color: theme.color.red
  })
);

const Input = styled(Text, {
  shouldForwardProp: prop =>
    prop === 'field' ||
    prop === 'validationSchema' ||
    prop === 'validateOnBlur' ||
    prop === 'validateOnChange' ||
    prop === 'initialValue' ||
    prop === 'height' ||
    prop === 'autocomplete' ||
    isPropValid(prop)
})(({ theme, label, isDirty, isShowError, disabled, inverted }) => ({
  position: 'relative',
  width: '100%',
  height: 46,
  borderRadius: 0,
  padding: `${TOP_PADDING}px ${X_PADDING}px`,
  border: 'none',
  outline: 'none',

  fontSize: 12,
  lineHeight: '30px',
  fontWeight: 'normal',
  transitionDuration: '0.3s',

  color: theme.color[inverted ? 'primary' : 'staticBlack'],
  backgroundColor: getBackgroundColor({ inverted, disabled, theme }),
  pointerEvents: disabled ? 'none' : 'auto',

  '&::placeholder': {
    color: !label ? theme.color.contrast4 : 'transparent',
    transitionDuration: '0.2s'
  },

  '& + .inputLabel': {
    top: isDirty ? LABEL_TOP_UP : LABEL_TOP_CENTER,
    color: getLabelColor({ disabled, isShowError, inverted, theme }),
    borderRadius: 3,
    backgroundColor:
      theme.color[
        disabled && !inverted
          ? 'staticGrey7'
          : inverted
          ? 'contrast'
          : 'staticWhite'
      ]
  },

  '&:hover': {
    borderColor: theme.color[isShowError ? 'red' : 'accent'],

    '& + .inputLabel': {
      color: theme.color[isShowError ? 'red' : 'blue']
    }
  },

  '&:focus': {
    '& + .inputLabel': {
      top: LABEL_TOP_UP,
      color: theme.color[isShowError ? 'red' : 'blue']
    },

    '&::placeholder': {
      color: theme.color.contrast4
    }
  }
}));

const StyledTextarea = styled(Input)(({ height }) => ({
  padding: 15,
  minHeight: height ? height : 150,
  lineHeight: '18px'
}));

export const TextInput = Input.withComponent(Text);
export const TextArea = StyledTextarea.withComponent(InformedTextArea);
