import Flex from 'components/Flex/Flex';
import Icon from 'components/Icon/Icon';
import { Text } from 'components/Typography/Typography';
import { GoogleLogo } from './GoogleLogo';

interface GoogleConnectionNotification {
  readonly connected: boolean;
  readonly error: boolean;
};

export const GoogleConnectionNotification = ({
  connected,
  error,
}: GoogleConnectionNotification) => {

  const message =
    error ? (
      connected ? 'Error disconnecting. Your Filmsupply and Google accounts are still connected.'
      : 'Error connecting. Email must match the email on your Filmsupply account.'
    ) : (
      connected ? 'Your Filmsupply and Google account have been connected.'
      : 'Your Google account has been disconnected.'
    );

  return (
    <div style={{ maxWidth: error ? 300 : 260 }}>
      <Flex alignItems="center">
        {
          error ? <ErrorIcon />
          : connected ? <GoogleLogo width={32} height={32} />
          : <SuccessIcon />
        }
        <Text
          size="14px"
          weight="600"
          lineHeight="18px"
          color="primary"
          css={{ marginLeft: 18 }}
        >
          {message}
        </Text>
      </Flex>
    </div>
  );
};

const ErrorIcon = () =>
  <Flex
    css={{
      minWidth: 32,
      minHeight: 32,
      borderRadius: '50%',
      background: '#E9383F',
      justifyContent: 'center',
      alignItems: 'center',
      svg: { path: { fill: 'white' } },
    }}
  >
    <Icon name="close" width={13} color="contrast" />
  </Flex>;

const SuccessIcon = () =>
  <Flex
    css={{
      minWidth: 32,
      minHeight: 32,
      borderRadius: '50%',
      background: '#16A34A',
      justifyContent: 'center',
      alignItems: 'center',
      svg: { path: { fill: 'white' } },
    }}
  >
    <Icon name="check" width={13} color="contrast" />
  </Flex>;
