import { useState } from 'react';
import Input from 'components/Forms/Deprecated/Input';
import Icon from 'components/Icon/Icon';
import * as yup from 'yup';

type PasswordInput = Record<string, any>;

export const PasswordInput = (props: PasswordInput) => {

  const [showingPassword, setShowingPassword] = useState(false);

  return (
    <div css={{ width: '100%', position: 'relative' }}>
      <Input
        {...props}
        field={props.field}
        type={showingPassword ? 'text' : 'password'}
      />
      <Icon
        name="view"
        size="lg"
        color="primary"
        css={{
          position: 'absolute',
          top: 25,
          right: 20,
          cursor: 'pointer',
        }}
        onClick={() => void setShowingPassword(!showingPassword)}
      />
    </div>
  )
};

// Schema used when re-entering a password (must match a previously entered password).
export const matchingPasswordSchema = (password: string) => yup
  .string()
  .oneOf([password], 'Passwords do not match.');

// Schema used when entering a password.
export const enteringPasswordSchema = yup
  .string()
  .required('Password is required.');

// Schema used when setting a password.
export const settingPasswordSchema = yup
  .string()
  .required('A password is required.')
  .min(8, 'The password must have at least 8 characters.')
  .matches(/[A-Z]/g, 'The password must contain at least one uppercase letter.')
  .matches(/[a-z]/g, 'The password must contain at least one lowercase letter.')
  .matches(/[0-9]/g, 'The password must contain at least one number.');
