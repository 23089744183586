import { GoogleIdentityProvider } from 'components/Auth/Providers/Google/GoogleIdentityProvider';
import { useMemo } from 'react';
import Auth from 'unstated/Auth';
import Cart from 'unstated/Cart';
import { cancelGooglePrompt } from 'utils/googleLogin/utils';

export const GooglePromptOnLoad = ({
  onSuccess,
  onFailure,
}: {
  onSuccess?: () => void;
  onFailure?: () => void;
}) => {
  const auth = Auth.useContainer();
  const { fetchCart } = Cart.useContainer();

  const notifySuccess = () => {
    fetchCart();
    onSuccess?.();
    window.setTimeout(cancelGooglePrompt, 100);
  };

  const context = useMemo(
    () => ({
      auth,
      viewIsLogin: true,
      viewIsRegister: false,
      notifySuccess,
      notifyFailure: onFailure,
    }),
    [auth, notifySuccess],
  );

  return (
    <GoogleIdentityProvider
      key="google-prompt"
      {...context}
      showButton={false}
      showLinkAccounts={() => {}}
      id="google-prompt"
    />
  );
};
