import React from 'react';
import * as Styled from './styles/StyledCreateAccountPopup';
import Icon from 'components/Icon/Icon';
import { T1, P1 } from 'components/Typography/Typography';
import { TextLink } from 'components/Button/Button';
import Auth from 'unstated/Auth';
import { AuthView } from 'components/Auth/Form/AuthView';
import { useAuthForm } from 'components/Auth/Form/AuthForm';
import { useLocation, useHistory } from 'react-router-dom';
import Modals from 'unstated/Modals';
import Logger from 'utils/logger';
import Cookies from 'universal-cookie';
import FeatureToggles from 'unstated/FeatureToggles';

const CreateAccountPopup = () => {
  const { completeProfileModal } = Modals.useContainer();
  const { toggles } = FeatureToggles.useContainer();
  const [showModal, setShowModal] = React.useState(true);
  const [restrict, setRestrict] = React.useState(false);
  const { user, requiresProfileCompletion } = Auth.useContainer();
  const location = useLocation();
  const history = useHistory();
  const cookies = new Cookies();
  const mountRef = React.useRef(false);

  const handleRegisterComplete = (view) => {
    const viewIsLogin = view === AuthView.Login;
    const redirectTo = viewIsLogin ? '/' : '/clips';
    const shouldShowCompleteProfileModal = viewIsLogin
      ? requiresProfileCompletion
      : true;

    void history.push(redirectTo);
    if (shouldShowCompleteProfileModal) void completeProfileModal.open();
  };

  const { AuthForm } = useAuthForm({
    initialView: AuthView.Register,
    onSuccess: handleRegisterComplete,
  });

  React.useEffect(() => {
    const restrictedUrls = [
      '/cart',
      '/cart/success',
      '/pricing',
      '/login',
      '/register',
      '/privacy-policy',
      '/terms-and-conditions',
      '/always-sunny',
    ];
    let isRestricted = false;
    restrictedUrls.forEach((url) => {
      if (location.pathname.includes(url)) {
        isRestricted = true;
      }
    });

    if (
      location.pathname.includes('/clips/') &&
      location.pathname.split('/').length === 4
    ) {
      isRestricted = true;
    }

    setRestrict(isRestricted);
  }, [location.pathname]);

  const closeModal = () => {
    setShowModal(false);
    Logger({
      event: 'Create Account Popup',
      actionType: 'Hide Popup',
      pageName: location.pathname,
    });
    cookies.set('filmsupply.hideCreateAccountPopup', 'hideCreateAccountPopup', {
      maxAge: 10 * 365 * 24 * 60 * 60,
    });
  };

  if (
    false
    // toggles.showCreateAccountPopup &&
    // !cookies.get('filmsupply.hideCreateAccountPopup') &&
    // !restrict &&
    // !user.email &&
    // showModal
  ) {
    if (!mountRef.current) {
      Logger({
        event: 'Create Account Popup',
        actionType: 'Show Popup',
        pageName: location.pathname,
      });
      mountRef.current = true;
    }

    return (
      <Styled.Container>
        <Icon
          name="close"
          size="xs"
          color="staticBlack"
          css={{ position: 'absolute', top: 17, right: 17, cursor: 'pointer' }}
          onClick={closeModal}
        />

        <AuthForm showLogo={true} />
      </Styled.Container>
    );
  } else return null;
};

export default CreateAccountPopup;
