import styled from '@emotion/styled';
import { Checkbox } from 'informed';

const size = 15;
export const CheckboxWrapper = styled.div({
  position: 'relative',
  display: 'block',
  width: size,
  height: size,
  flexShrink: 0,
  padding: 1
});
export const BaseCheckbox = styled.input(
  ({ theme, color, inverted, excluded, borderColor }) => ({
    position: 'absolute',
    marginLeft: -9999,
    visibility: 'hidden',
    '& + label': {
      cursor: 'pointer',
      position: 'absolute',
      width: size,
      height: size,
      left: '0px',
      top: '0px',
      margin: '0',
      transition: 'background .1s ease-in-out',
      borderRadius: 2,
      backgroundColor: theme.color.contrast2
    },
    '& + label:before, + label:after': {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      content: '""'
    },
    '& + label:before': {
      color: theme.color.orange
    },
    '& + label:after': {
      opacity: '0',
      width: '6px',
      height: '3px',
      background: 'transparent',
      top: 4,
      left: 3.5,
      transform: excluded ? 'none' : 'rotate(-45deg)',
      transition: 'opacity .1s ease-in-out',
      boxSizing: 'initial',
      border: `2px solid ${theme.color.staticWhite}`,
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: excluded ? 'none' : `2px solid ${theme.color.staticWhite}`
    },
    '& + label:hover': {
      backgroundColor: theme.color.blue
    },
    // Once Selected
    ':checked': {
      '& + label': {
        opacity: '1',
        backgroundColor: theme.color.blue
      },
      '& + label:after': {
        opacity: '1'
      }
    }
  })
);
export const CheckboxInput = BaseCheckbox.withComponent(Checkbox, {
  shouldForwardProp: prop => prop !== 'inverted'
});
