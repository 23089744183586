import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon/Icon';

import * as Styled from './styles/StyledNotification';

const Notification = ({
  showNotification,
  isFlash,
  closeNotification,
  fullWidth,
  maxWidth,
  ...props
}) => {
  return (
    <Styled.Notification
      showNotification={showNotification}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      {...props}
    >
      <Styled.CloseIcon
        onClick={() => closeNotification()}
        className="closeIcon"
        isFlash={isFlash}
      >
        <Icon name="close" size="xs" />
      </Styled.CloseIcon>
      {props.children}
    </Styled.Notification>
  );
};

export default Notification;
