import { useState } from 'react';
import { Media } from 'react-matches';
import baseForm from 'components/Forms/Form';
import Button from 'components/Button/Button';
import { useTheme } from '@emotion/react';
import { GoogleLogo } from 'utils/googleLogin/GoogleLogo';
import * as Styled from './styles/StyledLinkYourAccounts';
import Flex from 'components/Flex/Flex';
import { Text } from 'components/Typography/Typography';
import {
  enteringPasswordSchema,
  PasswordInput,
} from 'components/Forms/PasswordInput';
import Auth from 'unstated/Auth';
import Input from 'components/Forms/Deprecated/Input';
import { FilmsupplyLogo } from 'components/Auth/Form/FilmsupplyLogo';
import { Chain } from './Chain';
import { LinkingAccounts } from 'components/Auth/Form/AuthForm';
import { GoogleConnectionNotification } from 'utils/googleLogin/GoogleConnectionNotification';
import GlobalNotification from 'unstated/GlobalNotification';

interface LinkYourAccountsType {
  readonly data: LinkingAccounts;
  readonly onSuccess: (data?: any) => void;
  readonly onClose: () => void;
}

export const LinkYourAccounts = ({
  data,
  onSuccess,
  onClose,
}: LinkYourAccountsType) => {
  const [disabled, setDisabled] = useState(false);
  const [formError, setFormError] = useState('');
  const { updateUser, linkGoogleAccount } = Auth.useContainer();
  const { openNotification } = GlobalNotification.useContainer();
  const theme = useTheme();

  const handleEnter = (e, values) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleFormSubmit(values);
    }
  };

  const handleFormSubmit = async (password) => {
    void setDisabled(true);
    void setFormError('');

    const response = await linkGoogleAccount({
      password,
      challenge_token: data.challenge_token,
    });

    if (response.status !== 200) {
      void setFormError(
        response.data?.message || 'An error occurred. Please try again.',
      );
      void setDisabled(false);
    } else {
      void setFormError('');
      void updateUser();
      void onSuccess?.();
      void setDisabled(false);
      void openNotification(makeNotification('success', true));
    }
  };

  const EmailAndPasswordForm = baseForm(
    ({
      formState: { values, invalid, errors },
    }: {
      readonly formState: FormState;
    }) => (
      <Media queries={theme.queries}>
        {({ matches }) => (
          <Flex backgroundColor="contrast" column css={{ gap: '12px' }}>
            <Styled.Logos>
              <FilmsupplyLogo />
              <Chain />
              <div>
                <GoogleLogo width={40} height={40} />
              </div>
            </Styled.Logos>

            <Styled.Heading>
              <Styled.Title>Link your accounts</Styled.Title>
              <Styled.Description>
                Enter your Filmsupply password to link your Google Account with
                your existing Filmsupply account.
              </Styled.Description>
            </Styled.Heading>

            <Styled.Section>
              <Input
                //@ts-ignore
                inverted
                type="email"
                field="email"
                defaultValue={data.email}
                disabled
              />

              <PasswordInput
                inverted
                label="Password"
                field="password"
                type="password"
                disabled={disabled}
                autocomplete="current-password"
                validationSchema={enteringPasswordSchema}
                errorMessage={errors.password}
                onKeyDown={(event) => {
                  handleEnter(event, values.password);
                }}
              />

              <Text color="red" size="14">
                {formError}
              </Text>
            </Styled.Section>

            <Flex
              css={{
                flexDirection: matches.sm ? 'column' : 'row',
                gap: '15px',
                width: '100%',
              }}
            >
              <Button
                bold
                full
                outlineColor="contrast2"
                color="contrast"
                type="button"
                disabled={disabled}
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                bold
                full
                type="submit"
                disabled={!values.password || invalid || disabled}
                onClick={() => void handleFormSubmit(values.password)}
              >
                Login
              </Button>
            </Flex>
          </Flex>
        )}
      </Media>
    ),
  );

  return <EmailAndPasswordForm />;
};

const makeNotification = (type: 'success' | 'error', connected: boolean) => ({
  content: (
    <GoogleConnectionNotification
      error={type === 'error'}
      connected={connected}
    />
  ),
  flash: true,
  fullWidth: false,
  maxWidth: 350,
});
