import styled from '@emotion/styled';

export const Logos = styled.div({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
});

export const ChainContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  gap: '4px',
  alignItems: 'center',
});

export const LineContainer = styled.div({
  flex: 1,
});

export const Line = styled.hr({
  height: '1px',
  width: '40px',
  border: 0,
  background: '#E2E3E4',
});

export const Heading = styled.span({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  marginTop: '12px',
});

export const Title = styled.span(
  ({ theme }) => ({
    fontSize: 24,
    fontFamily: 'Proxima Nova',
    lineHeight: '130%',
    fontWeight: 700,
    fontStyle: 'normal',
    color: theme.color.primary,
  })
);

export const Description = styled.span(
  ({ theme }) => ({
    fontSize: 14,
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '18px',
    color: theme.color.primary,
  })
);

export const Section = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  '& > div:first-of-type': {
    paddingBottom: 0,
  },
});
