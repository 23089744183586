import React from 'react';
import { createContainer } from 'unstated-next';
import useAxios from 'hooks/useAxios';
import Auth from 'unstated/Auth';

function useCart() {
  const [{ data, ...response }, cartAPI] = useAxios({ baseURL: '/cart' });
  const { user, initialFetchComplete } = Auth.useContainer();
  const [cartLicenses, setCartLicenses] = React.useState([]);
  const [cart, setCart] = React.useState(data);
  const [popoverOpen, setPopoverOpen] = React.useState(false);

  const stored_hash = window.localStorage.getItem('cart_hash');
  /**
   * For guest users we are saving the cart hash to local storage.
   * When we make requests the cart hash is determined by the below:
   */
  const cart_hash = cart?.cart_hash
    ? cart.cart_hash
    : !cart?.cart_hash && user?.email
    ? null
    : !cart?.cart_hash && !user?.email && stored_hash
    ? stored_hash
    : null;

  React.useEffect(() => {
    if (response.success) setCart(data);
    if (!user?.email && data?.cart_hash)
      window.localStorage.setItem('cart_hash', data.cart_hash);
  }, [setCart, data, response.success, user?.email]);

  const fetchCart = React.useCallback(
    () => cartAPI.get('', { params: { ...(cart_hash && { cart_hash }) } }),
    [cartAPI, cart_hash]
  );

  /**
   * Wait until initial fetchUser is complete to fetch initial cart
   * Also, fetch cart after a user logs out or logs in
   */
  React.useEffect(() => {
    if (initialFetchComplete) fetchCart();
  }, [initialFetchComplete, fetchCart]);

  const addCartItems = ({ clips }) =>
    cartAPI.post('/clips', { clips, ...(cart_hash && { cart_hash }) });

  const updateCartItems = clips =>
    cartAPI.put('/clips', { clips, ...(cart_hash && { cart_hash }) });

  const removeCartItem = cart_item_id =>
    cartAPI.delete('', {
      params: {
        cart_item_ids: `[${cart_item_id}]`,
        ...(cart_hash && { cart_hash })
      }
    });

  const clearCart = () =>
    cartAPI.delete('/clear', { params: { ...(cart_hash && { cart_hash }) } });

  const addCoupon = ({ code }) =>
    cartAPI.post('/coupon', { code, ...(cart_hash && { cart_hash }) });

  const removeCoupon = () =>
    cartAPI.delete('/coupon', { params: { ...(cart_hash && { cart_hash }) } });

  const triggerPopover = () =>
    setTimeout(() => {
      setPopoverOpen(true);
      setTimeout(() => setPopoverOpen(false), 4000);
    }, 500);

  const groupCartLicenses = React.useCallback(cart_items => {
    let licenses = [];
    if (!cart_items) return licenses;

    // create licenses array
    cart_items.map(item => {
      const { license_id, full_name, distribution_ids } = item.licenses.data[0];
      const { format, ...licenseOptions } = full_name;
      const id_prefix = license_id.substring(0, 7);
      const distribution_id = parseInt(license_id.substring(6, 7));

      let license = {
        id_prefix,
        ...licenseOptions,
        distribution_ids: distribution_ids || [],
        distribution_id,
        cart_items: []
      };
      licenses.push(license);
      return null;
    });

    // remove duplicate licenses
    licenses = licenses.reduce((arr, item) => {
      return arr.filter(lic => lic.id_prefix === item.id_prefix).length
        ? arr
        : [...arr, item];
    }, []);

    // Order by org_size
    licenses.sort((lic1, lic2) => lic1.id_prefix[4] - lic2.id_prefix[4]);

    // sort cart items into licenses
    cart_items.map(item => {
      const id_prefix = item.licenses.data[0].license_id.substring(0, 7);
      licenses.map((lic, i) => {
        if (lic.id_prefix === id_prefix) licenses[i].cart_items.push(item);
        return null;
      });
      return null;
    });

    setCartLicenses(licenses);
  }, []);

  React.useEffect(() => {
    groupCartLicenses(cart?.cart_items?.data);
  }, [cart, groupCartLicenses]);

  return {
    response,
    cart,
    cartLicenses,
    fetchCart,
    addCartItems,
    updateCartItems,
    removeCartItem,
    addCoupon,
    removeCoupon,
    clearCart,
    popoverOpen,
    triggerPopover
  };
}

export default createContainer(useCart);
