import { useMedia as useReactUseUseMedia } from 'react-use';
import { findLastKey } from 'lodash';

export function useMedia(queries) {
  const { sm, md, lg, xl, xxl, xxxl } = queries;

  const isSm = useReactUseUseMedia(`(max-width: ${sm.maxWidth}px)`);
  const isMd = useReactUseUseMedia(`(min-width: ${md.minWidth}px)`);
  const isLg = useReactUseUseMedia(`(min-width: ${lg.minWidth}px)`);
  const isXl = useReactUseUseMedia(`(min-width: ${xl.minWidth}px)`);
  const isXxl = useReactUseUseMedia(`(min-width: ${xxl.minWidth}px)`);
  const isXxxl = useReactUseUseMedia(`(min-width: ${xxxl.minWidth}px)`);

  const matches = {
    isSm,
    isMd,
    isLg,
    isXl,
    isXxl,
    isXxxl
  };

  const largestSizeKey = findLastKey(matches, isSize => isSize);

  return {
    isSm: 'isSm' === largestSizeKey,
    isMd: 'isMd' === largestSizeKey,
    isLg: 'isLg' === largestSizeKey,
    isXl: 'isXl' === largestSizeKey,
    isXxl: 'isXxl' === largestSizeKey,
    isXxxl: 'isXxxl' === largestSizeKey
  };
}
