import { useMemo, useState } from 'react';
import { noop } from 'utils/utils';
import { FilmsupplyLogo } from './FilmsupplyLogo';
import Flex from 'components/Flex/Flex';
import { TextLink } from 'components/Button/Button';
import { AuthView, CallbackWithView } from './AuthView';
import * as Styled from './styles/StyledAuthForm'
import { Or } from './Or';
import Auth from 'unstated/Auth';
import Cart from 'unstated/Cart';
import { StandardIdentityProvider } from '../Providers/Standard/StandardIdentityProvider';
import { GoogleIdentityProvider } from '../Providers/Google/GoogleIdentityProvider';
import { LinkYourAccounts } from '../Providers/Google/LinkYourAccounts';
import { cancelGooglePrompt } from 'utils/googleLogin/utils';

interface UseAuthFormInput {
  readonly initialView: AuthView; // Which of the Login or Register functionalities should be initially shown.
  readonly onSuccess?: CallbackWithView; // Called if login/registration (indicated by the argument) has succeeded.
  readonly onViewChange?: CallbackWithView; // You consume a single AuthForm component that internally switches between the Login and Register functionalities. You can't cause the switch from the outside of this component, but you do get a callback function which notifies you about this having happened (so you can update the URL for example).
  readonly noRedirect?: boolean; // Avoids redirecting to /clips on login
  readonly id?: string;
}

interface UseAuthFormOutput {
  readonly AuthForm: (props: AuthForm) => JSX.Element;
}

type UseAuthForm = (input: UseAuthFormInput) => UseAuthFormOutput;

interface AuthForm {
  readonly showLogo?: boolean; // Whether to show the small 'F' logo above the form.
}

export interface LinkingAccounts {
  readonly email: string;
  readonly challenge_token: string;
}

export const useAuthForm: UseAuthForm = ({
  initialView,
  onSuccess = noop,
  onViewChange = noop,
  noRedirect,
  id = 'google-button-container'
}) => {
  const auth = Auth.useContainer();
  const { fetchCart } = Cart.useContainer();

  const [view, setView] = useState(initialView);
  const [linkingAccounts, setLinkingAccounts] =
    useState<LinkingAccounts | null>(null);

  const notifySuccess = (data?: any) => {
    void cancelGooglePrompt();
    if (context.viewIsLogin) void fetchCart();
    void onSuccess?.(view, data);
    void window.setTimeout(cancelGooglePrompt, 100);
  };

  const context = useMemo(() => ({
    auth,
    viewIsLogin: view === AuthView.Login,
    viewIsRegister: view === AuthView.Register,
    notifySuccess,
    notifyFailure: () => null,
  }), [view, notifySuccess]);

  const changeView = (newView: AuthView) => {
    void setView(newView);
    void onViewChange(newView);
  };

  const LoginRegisterView = ({ showLogo = true }: AuthForm) => (
    linkingAccounts === null ?
    <Flex column css={{ gap: '40px' }}>
      <Styled.Section>
        {showLogo && <FilmsupplyLogo style={{ marginBottom: '24px' }} />}
        <Styled.Heading>
          <Styled.Accent>Find</Styled.Accent> Inspiration.
        </Styled.Heading>
        <Styled.Heading>
          <Styled.Accent>Discover</Styled.Accent> The Perfect Footage.
        </Styled.Heading>
        <Styled.Heading>
          <Styled.Accent>Create</Styled.Accent> Beautiful Projects.
        </Styled.Heading>
        <Styled.Headline>
          {context.viewIsLogin
            ? 'Log in to get started.'
            : 'Create a free account. No credit card needed.'}
        </Styled.Headline>
      </Styled.Section>

        <Styled.Section>
          <StandardIdentityProvider
            {...context}
          />
          <Or />
          <GoogleIdentityProvider
            {...context}
            showLinkAccounts={setLinkingAccounts}
            noRedirect={noRedirect}
            id={id}
          />
        </Styled.Section>

      <Styled.Section>
        {context.viewIsLogin ? (
          <Styled.Paragraph>
            Don't have an account?{' '}
            <TextLink
              underline
              onClick={() => void changeView(AuthView.Register)}
              textComponentStyles={linkCss}
              css={paraCss}
            >
              Create Free Account
            </TextLink>
          </Styled.Paragraph>
        ) : (
          <>
            <Styled.Paragraph>
              By creating an account, I agree to the
              <br /> Filmsupply{' '}
              <TextLink
                underline
                to="/terms-and-conditions"
                target="_blank"
                textComponentStyles={linkCss}
                css={paraCss}
              >
                Terms
              </TextLink>{' '}
              &{' '}
              <TextLink
                underline
                to="/privacy-policy"
                target="_blank"
                textComponentStyles={linkCss}
                css={paraCss}
              >
                Privacy Policy
              </TextLink>
              .
            </Styled.Paragraph>
            <Styled.Paragraph css={{ marginTop: '24px' }}>
              Already have an account?{' '}
              <TextLink
                underline
                onClick={() => void changeView(AuthView.Login)}
                textComponentStyles={linkCss}
                css={paraCss}
              >
                Login
              </TextLink>
            </Styled.Paragraph>
          </>
        )}
      </Styled.Section>
    </Flex>

    : <LinkYourAccounts
        data={linkingAccounts}
        onSuccess={notifySuccess}
        onClose={() => void setLinkingAccounts(null)}
    />
  );

  return {
    AuthForm: LoginRegisterView,
  };
};

const paraCss = {
  color: '#73767A', // This maps to theme.colors.staticGrey4 or theme.colors.contrast4
  fontSize: '14px',
  fontWeight: 400,
  fontStyle: 'normal',
  lineHeight: '18px',
  margin: 0,
  textAlign: 'center' as const,
};

const linkCss = {
  fontSize: '14px !important',
  color: '#73767A !important',
};
