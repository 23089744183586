import { useState } from 'react';
import { useMountEffect } from '@react-hookz/web';
import { useMedia } from 'hooks/use-media';
import {
  promptGoogleConsent,
  GoogleButtonProps,
  initializeGoogleSignIn,
  renderGoogleButton,
} from './utils';
import Auth from 'unstated/Auth';
import { useTheme } from '@emotion/react';

interface UseGoogleLoginProps {
  readonly buttonProps: GoogleButtonProps;
  readonly onSuccess?: (credential: string) => void;
  readonly onFailure?: (reason: string) => void;
  readonly onWorking?: () => void;
  enabled?: boolean;
}

const GoogleButton = ({ id }: { id?: string }) => (
  <div
    id={id}
    style={{
      width: '100%',
      minHeight: '40px',
      maxHeight: '40px',
      display: 'flex',
      justifyContent: 'center',
    }}
  />
);

export const useGoogleLogin = ({
  onSuccess,
  onFailure,
  buttonProps,
}: UseGoogleLoginProps) => {
  const { queries } = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { googleCredential, isLoggingOut, user } = Auth.useContainer();
  const { isSm } = useMedia(queries);

  void useMountEffect(() => {
    if (!isLoggingOut) {
      void initializeGoogleSignIn((credential) => {
        void onSuccess?.(credential);
        void setIsLoading(false);
      });
      if (!googleCredential && !user) {
        void promptGoogleConsent((reason) => {
          if (reason) void setError(reason);
          void onFailure?.(reason);
        });
      }
      const width = buttonProps.width || isSm ? 300 : 400; // GSI limits the width to 400px.
      void renderGoogleButton({
        ...buttonProps,
        width,
        id: buttonProps.id,
      });
    }
  });

  return {
    error,
    isLoading,
    GoogleButton,
  };
};
