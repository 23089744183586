import lightTheme from '../../themes/lightTheme';
import { Link, NavLink } from 'react-router-dom';
import { P1, P2, P3, P4, T1, T2 } from 'components/Typography/Typography';
import * as Styled from './styles/StyledButton';
import { ReactNode } from 'react';
import { cursor } from 'components/Icon/icons';

type Color = keyof typeof lightTheme.color

interface Button {
  readonly className?: string
  readonly children: ReactNode
  readonly size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'           // Specifies the height of the button.
  readonly color?: Color                                      // Specifies what color the button should be.
  readonly hoverColor?: Color                                 // Specifies what color the button hover state should be.
  readonly iconHoverColor?: Color                             // Specifies what color the icon hover state should be.
  readonly iconColor?: Color                                  // Specifies the initial color of the icon.
  readonly type?: 'button' | 'submit' | 'reset'               // Specifies what type the button should be.
  readonly iconPlacement?: 'left' | 'right'                   // Specifies what side the icon should be on.
  readonly full?: boolean                                     // Specifies if a button should be full-width.
  readonly disabled?: boolean                                 // Indicates when the button is disabled.
  readonly inverted?: boolean                                 // Inverts disabled button colors.
  readonly bold?: boolean                                     // Bolds button text.
  readonly semibold?: boolean                                 // SemiBolds button text.
  readonly iconOnly?: boolean                                 // Use for buttons with only an icon as it's children.
  readonly outline?: boolean                                  // Renders an outline button.
  readonly outlineColor?: Color                               // Specifies what color the outline should be.
  readonly nopad?: boolean                                    // Removes button padding.
  readonly to?: string                                        // Page to navigate to when clicked.
  readonly href?: string                                      // Web link to navigate to when clicked.
  readonly target?: '_blank' | '_self' | '_top' | '_parent'   // Specifies how the external link should be opened.
  readonly leaveSVGAsIs?: boolean                             // Don't override the styles of SVG elements inside the button.
  readonly [prop: string]: any
}

const Button = ({
  to,
  href,
  children,
  className,
  disabled = false,
  inverted = false,
  size = 'lg',
  type = 'button',
  target = '_self',
  iconPlacement = 'left',
  ...restProps
}: Button) => {

  const linkStyle = {
    position: 'absolute' as const,
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  };

  // creating a new props object to pass to styled components removes all emotion and React DOM warnings.
  // https://reactjs.org/warnings/unknown-prop.html
  const styledProps = { size, inverted, ...restProps };

  if (to && !disabled) {
    return (
      <Styled.Button className={className} type={type} {...styledProps}>
        {children}
        <Link
          to={to}
          css={linkStyle}
          aria-label={restProps['aria-label'] || ''}
        />
      </Styled.Button>
    );
  } else if (href && !disabled) {
    return (
      <Styled.Button className={className} type={type} {...styledProps}>
        {children}
        <a
          href={href}
          target={target}
          //@ts-ignore
          css={linkStyle}
          aria-label={restProps['aria-label'] || ''}
        >
          {null}
        </a>
      </Styled.Button>
    );
  } else {
    return (
      <Styled.Button className={className} {...styledProps} disabled={disabled}>
        {children}
      </Styled.Button>
    );
  }
};

interface TextLinkBase {
  readonly children: ReactNode                                        // Specifies which text component should be rendered.
  readonly id?: string
  readonly textComponent?: 'T1' | 'T2' | 'P1' | 'P2' | 'P3' | 'P4'
  readonly hoverColor?: Color                                         // Specifies whiat color the button hover state should be.
  readonly color?: Color                                              // Specifies whiat color the button should be.
  readonly className?: string
  readonly nav?: boolean,
  readonly textComponentStyles?: any
  readonly disabled?: boolean
  readonly [prop: string]: any
}

interface TextLinkWithHref extends TextLinkBase {
  readonly href: string                                               // Web link to navigate to when clicked.
  readonly target?: string
  readonly rel?: string
}

interface TextLinkWithTo extends TextLinkBase {
  readonly to: string                                                 // Page to navigate to when clicked.
}

interface TextLinkWithOnClick extends TextLinkBase {
  readonly onClick: () => void
}

type TextLink =
  | TextLinkWithHref
  | TextLinkWithTo
  | TextLinkWithOnClick

const componentByName = {
  T1,
  T2,
  P1,
  P2,
  P3,
  P4,
}

const TextLink = ({
  href,
  to,
  textComponent = 'P2',
  children,
  hoverColor,
  color = 'primary',
  className,
  nav,
  textComponentStyles,
  id,
  disabled = false,
  onClick,
  ...restProps
}: TextLink) => {

  const Component = componentByName[textComponent] ?? P2

  const BaseComponent = () =>
    <Component {...restProps} css={textComponentStyles}>
      {children}
    </Component>

  if (!href && !to) {
    return (
      <Styled.Anchor
        id={id}
        className={className}
        //@ts-ignore
        hoverColor={hoverColor}
        color={color}
        onClick={onClick}
        {...restProps}
        css={disabled ? disabledCss : { ...defaultCss, cursor: 'pointer' } }
      >
        <BaseComponent />
      </Styled.Anchor>
    );
  } else if (href) {
    return (
      <Styled.Anchor
        id={id}
        className={className}
        href={href}
        //@ts-ignore
        hoverColor={hoverColor}
        color={color}
        css={disabled ? disabledCss : defaultCss}
        onClick={onClick}
        {...restProps}
      >
        <BaseComponent />
      </Styled.Anchor>
    );
  } else if (to) {
    if (nav) {
      return (
        <Styled.Anchor
          id={id}
          as={NavLink}
          className={className}
          //@ts-ignore
          to={to}
          hoverColor={hoverColor}
          color={color}
          css={disabled ? disabledCss : defaultCss}
          onClick={onClick}
          {...restProps}
        >
          <BaseComponent />
        </Styled.Anchor>
      );
    }
    return (
      <Styled.Anchor
        id={id}
        as={Link}
        className={className}
        //@ts-ignore
        to={to}
        hoverColor={hoverColor}
        color={color}
        css={disabled ? disabledCss : defaultCss}
        onClick={onClick}
        {...restProps}
      >
        <BaseComponent />
      </Styled.Anchor>
    );
  } else {
    return null;
  }
};

const defaultCss = {
  cursor: 'pointer',
}

const disabledCss = {
  cursor: 'default',
  pointerEvents: 'none' as const,
  color: '#F3F4F4 !important',
}

export default Button;

export { TextLink };
