export type GoogleCredential =
  google.accounts.id.CredentialResponse['credential'];
type GsiButtonConfiguration =
  Partial<google.accounts.id.GsiButtonConfiguration>;
export interface GoogleButtonProps extends GsiButtonConfiguration {
  id: string;
}
type Notification = google.accounts.id.PromptMomentNotification;
type OnSuccess = (credential: GoogleCredential) => void;
type OnError = (reason: string) => void;

/** We need to be able to allow auto logins on certain pages/instances, but only those
 * pages. Otherwise Google will automatically reconnect on logout too.
 */
const AUTO_LOGIN_ROUTES = ['/account/password'];

export const initializeGoogleSignIn = (onSuccess: OnSuccess) => {
  window.google?.accounts.id.initialize({
    //@ts-ignore Note: 'log_level' is an undocumented property.
    log_level: process.env.NODE_ENV === 'development' ? 'debug' : 'error',
    client_id: import.meta.env.VITE_APP_GOOGLE_OAUTH_CLIENT_ID,
    callback: ({ credential }) => void onSuccess?.(credential),
    auto_select: AUTO_LOGIN_ROUTES.includes(window.location.pathname),
    cancel_on_tap_outside: false,
    use_fedcm_for_prompt: true,
  });
};

export const promptGoogleConsent = (onError: OnError) =>
  void window.google?.accounts.id.prompt(handleGooglePromptMoment(onError));

export const cancelGooglePrompt = () =>
  void window.google?.accounts.id.cancel();

export const signOutGoogle = () => {
  /**
   * This ensure Google signs out properly
   * https://developers.google.com/identity/gsi/web/reference/js-reference#google.accounts.id.disableAutoSelect
   */
  window.google.accounts.id.disableAutoSelect();
};

export const renderGoogleButton = ({
  id,
  ...gsiButtonProps
}: GoogleButtonProps) => {
  const googleButtonContainer = window.document.getElementById(id)!;
  void window.google?.accounts.id.renderButton(googleButtonContainer, {
    theme: 'outline',
    size: 'large',
    logo_alignment: 'center',
    type: 'standard',
    ...gsiButtonProps,
  });
};

const handleGooglePromptMoment =
  (onError: OnError) => (notification: Notification) => {
    // Check the type of moment (dismissal, skipping, not displayed)

    const credentialWasReturned =
      notification.isDismissedMoment?.() &&
      notification.getDismissedReason() === 'credential_returned';

    const reason = notification.isNotDisplayed?.()
      ? `Google One-Tap prompt was not displayed, reason: ${notification.getNotDisplayedReason()}.` // Handle not displayed cases.
      : notification.isSkippedMoment()
        ? `Google One-Tap prompt was skipped, reason: ${notification.getSkippedReason()}.` // Handle skipped moments.
        : notification.isDismissedMoment?.()
          ? `Google One-Tap prompt was dismissed by the user, reason: ${notification.getDismissedReason()}.` // Handle dismissed moments.
          : ''; // No error.

    if (reason !== '') {
      if (!credentialWasReturned) void onError(reason);
    }
  };
