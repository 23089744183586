import { useGoogleLogin } from 'utils/googleLogin/useGoogleLogin';
import { IdentityProvider } from '../IdentityProvider';
import { GoogleCredential } from 'utils/googleLogin/utils';
import { LinkingAccounts } from 'components/Auth/Form/AuthForm';
import { useHistory } from 'react-router';

interface GoogleIdentityProviderType {
  readonly showLinkAccounts: (value: LinkingAccounts | null) => void;
  isAccountConnectLogin?: boolean;
  readonly noRedirect?: boolean;
  readonly id: string;
  showButton?: boolean;
}

const REDIRECT_RESTRICTED_ROUTES = ['/clips', '/cart'];

export const GoogleIdentityProvider: IdentityProvider<
  GoogleIdentityProviderType
> = ({
  auth,
  viewIsLogin,
  notifySuccess,
  notifyFailure,
  showLinkAccounts,
  isAccountConnectLogin = false,
  noRedirect,
  id,
  showButton = true,
}) => {
  const action = viewIsLogin ? auth.signInWithGoogle : auth.registerWithGoogle;
  const history = useHistory();

  const onGoogleSuccess = async (credential: GoogleCredential) => {
    if (isAccountConnectLogin) {
      /**
       * This handles signin with google and connecting to the users FS
       * account in /account/password
       */
      const connectReq = await auth.connectGoogleAccount({
        google_credential: credential,
      });
      if (connectReq.status === 200) {
        notifySuccess?.();
      }
    } else {
      /**
       * This handles signin/signup with google
       */
      try {
        const { data } = await action({ google_credential: credential });

        if (data.challenge === 'password_confirmation') {
          void showLinkAccounts({
            email: data.email,
            challenge_token: data.challenge_token,
          });
        } else {
          if (
            !noRedirect &&
            !REDIRECT_RESTRICTED_ROUTES.includes(history?.location?.pathname)
          ) {
            history.push('/clips');
          }
          void notifySuccess?.(data);
          void auth.updateUser();
        }
      } catch (error: any) {
        void notifyFailure?.(error);
      }
    }
  };

  const { GoogleButton } = useGoogleLogin({
    onSuccess: onGoogleSuccess,
    onFailure: notifyFailure,
    buttonProps: {
      text: 'continue_with',
      id,
    },
  });

  return !auth.isLoggingOut && showButton ? <GoogleButton id={id} /> : null;
};
